@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: "Comfortaa", "cursive";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: auto !important;
  appearance: auto !important;
}

body ul {
  padding: 0;
  margin: 0;
}

body li {
  list-style: none;
  padding: 0;
  margin: 0;
}

.img-contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.plr-50 {
  padding-left: 50px;
  padding-right: 50px;
}

.pt-50 {
  padding-top: 50px;
}

.pb-50 {
  padding-bottom: 50px;
}

body a {
  text-decoration: none;
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
body p {
  margin: 0;
  padding: 0;
  line-height: normal;
}

.btn-close:focus,
input:focus,
.form-select:focus,
.form-check-input:focus {
  box-shadow: none;
  border-color: inherit;
}

.img-cotain {
  width: 100% !important;
  height: 100% !important;
  object-fit: contain !important;
}

.title {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 30px;
}

.font-24-semibold{
  font-size: 22px;
  font-weight: 700;
}

.descri {
  font-size: 22px;
  font-weight: 300;
}

.main-bg {
  background-color: #eaf6fc;
  width: 100%;
  min-height: 100vh;
}

.new-container {
  max-width: 1644px;
  margin: auto;
  width: 100%;
}

.white-card {
  background-color: #fff;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 20px 50px;
}

header .image-box {
  width: 220px;
  height: auto;
}

header ul {
  display: flex;
  align-items: center;
  gap: 30px;
}

header ul li {
  font-size: 20px;
  font-weight: 700;
  color: #5182b2;
}

header ul li span {
  margin-right: 10px;
}

.lang-select {
  display: flex;
  align-items: center;
}

.lang-select .form-select {
  background-color: transparent;
  border: none;
  width: fit-content;
  font-size: 20px;
  font-weight: 700;
  color: #5182b2;
  cursor: pointer;
  padding-top: 0;
  padding-bottom: 0;
}

.login-canvas {
  width: 400px;
  padding: 0;
}

.canvas-title {
  font-size: 18px;
  color: #5182b2;
}

.offcanvas {
  border: none !important;
}

.login-canvas .offcanvas-header {
  padding: 40px;
}

.login-canvas .offcanvas-body {
  padding: 0 40px 20px;
}

.login-canvas .offcanvas-body::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.login-canvas .offcanvas-body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.login-canvas .offcanvas-body::-webkit-scrollbar-thumb {
  background: #5182b2;
}

/* Handle on hover */
.login-canvas .offcanvas-body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.login-canvas .form-group {
  margin-bottom: 20px;
}

.login-canvas .form-group label {
  font-size: 16px;
  color: #5182b2;
  margin-bottom: 5px;
  display: block;
}

.login-canvas .form-group input {
  border: 1px solid #5182b2;
  border-radius: 5px;
  height: 60px;
  width: 100%;
  color: #5182b2;
  padding: 5px 20px;
}

.login-canvas .offcanvas-body .sign-up span {
  margin-left: 5px;
}

input:focus,
input:focus-visible {
  box-shadow: none;
  outline: none;
}

/* login / sign up canvs start */

.login-canvas .form-group input::placeholder {
  color: #5182b2;
}

.login-canvas .form-check {
  color: #5182b2;
  padding: 0;
}

.login-canvas .form-check input {
  margin-right: 5px;
}

.login-canvas .form-check .form-check-input {
  border-color: #5182b2;
}

.login-canvas .form-check .form-check-input:checked {
  background-color: #5182b2;
  border-color: #5182b2;
}

.forget-btn {
  border: none;
  background-color: transparent;
  padding: 0;
  font-size: 14px;
  color: #5182b2;
}

.login-canvas .btn {
  font-size: 16px;
  width: 100%;
  text-transform: uppercase;
}

.login-canvas .sign-up span {
  text-decoration: underline;
}

.login-canvas p {
  font-size: 14px;
  color: #5182b2;
  margin: 0;
  margin-top: 20px;
  text-align: center;
}

.login-canvas .signup-with {
  margin-top: 30px;
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 30px;
}

.login-canvas .signup-with li {
  cursor: pointer;
  width: 50px;
  height: 50px;
}

.sign-up-canvas .form-group input {
  height: 40px;
}

.sign-up-canvas .form-group .form-check {
  display: flex;
}

.sign-up-canvas .form-group .form-check [type="radio"] {
  height: unset;
  width: unset;
  accent-color: #5182b2;
}

button.p-confirm-dialog-reject{
  border-color: #5182b2;
  background-color: transparent;
  color: #0071b8;
  margin-right: 10px;
  border-radius: 5px;
}

button.p-confirm-dialog-accept{
  background-color: #b1d724;
  color: #0071b8;
  border-color: #b1d724;
  border-radius: 5px;
}

.p-button:focus {
  box-shadow: none;
}
/* login / sign up canvs end */

/* our story start */

.our-story {
  display: grid;
  gap: 0 50px;
  border-bottom: 1px solid #eaf6fc;
}

.our-story .image-box {
  grid-row: 1 / span 2;
  width: 210px;
  height: 112px;
}

.our-story .descri {
  grid-column: 2;
  grid-row: 2;
}

.story-padding {
  padding-top: 125px;
  padding-bottom: 100px;
}

.apr-padding {
  padding-bottom: 125px;
  padding-top: 100px;
}

/* our story end */

/* apr start */

.apr-section {
  border-bottom: 1px solid #eaf6fc;
}

.apr-section .title {
  text-align: center;
  margin-bottom: 50px;
}

.apr-section .inner-box {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 50px;
  max-width: 1300px;
  margin: auto;
}

.apr-section .inner-box .box {
  /* flex-basis: 33.33%; */
  display: flex;
  gap: 20px;
}

.apr-section .inner-box .box .image-box{
  width: 45px;
  height: 50px;
  flex-shrink: 0;
}

/* apr end */

/* home banner start */

.region-page,
.result-sec,
.knowledge-page,
.subdvision-page,
.test-lang-sec,
.parent-details-page,
.forcetest-inst,
.custom-min-h {
  min-height: calc(100vh - 118.92px);
}
/* .result-sec{
    display: flex;
    align-items: center;
    justify-content: center;
} */

.subdvision-page .sec-heading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.subdvision-page .sec-heading .form-select {
  font-size: 48px;
  border: none;
  background-image: none;
  padding-right: 12px;
}

.subdvision-page .sec-heading .form-select option {
  font-size: 16px;
}

.subdvision-page .sec-heading span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-banner {
  display: grid;
  grid-template-columns: 40% 56.5%;
  gap: 0 50px;
  padding: 100px;
  min-height: calc(100vh - 118.92px);
}

.home-banner .image-box {
  grid-row: 1 / span 4;
}

.home-banner h1 {
  grid-column: 2;
  grid-row: 1;
  font-size: 55px;
  font-weight: 700;
  margin-bottom: 80px;
  color: #5182b2;
  text-align: center;
}

.yellow-color {
  color: #f8bd01 !important;
}

.pink-color {
  color: #e61c63 !important;
}

.green-color {
  color: #b1d724 !important;
}

.lime-green-color{
  color: #BACF1B !important;
}

.blue-color {
  color: #2fa4dd !important;
}

.dark-blue-color{
  color: #5182b2 !important;
}

.pink-label{
  font-size: 18px;
  padding: 15px 40px;
  border: 1px solid transparent;
  font-weight: 600;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #e61c63;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  min-width: 300px;
}

.green-label{
  font-size: 18px;
  padding: 15px 40px;
  border: 1px solid transparent;
  font-weight: 600;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #BACF1B;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  min-width: 300px;
}

.home-banner p {
  grid-column: 2;
  grid-row: 2;
  font-size: 17px;
  margin-bottom: 70px;
  color: #0071b8;
  line-height: 36px;
  text-align: center;
}

.home-banner .action-btn {
  display: flex;
  gap: 15px;
  justify-content: center;
}

/* home banner end */

.btn {
  font-size: 18px;
  padding: 15px 40px;
  border: 1px solid transparent;
  font-weight: 600;
  height: fit-content;
}

.btn.yellow-btn {
  background-color: #f8bd01;
  color: #fff;
}

.btn.green-btn {
  background-color: #b1d724;
  color: #0071b8;
  border-color: #b1d724;
}

.btn.green-btn:hover {
  border-color: inherit;
}

.btn.blue-btn {
  border-color: #5182b2;
  background-color: transparent;
  color: #0071b8;
}

.btn.pink-btn {
  background-color: #e61c63;
  color: #fff;
  border-color: #e61c63;
}

/* historic start */

.historic-sec h2,
.sec-heading {
  color: #5182b2;
  font-size: 44px;
  margin-bottom: 25px;
  text-align: center;
}

.historic-sec h2 .black-color {
  color: #000;
}

.historic-sec .action-btn {
  display: flex;
  gap: 20px;
}

.historic-sec .action-btn .btn {
  max-width: 300px;
  width: 100%;
  height: unset;
  display: flex;
  align-items: center;
  justify-content: center;
}

.historic-sec .inner-box {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: 50px;
}

.historic-sec .inner-box .map-box {
  max-width: 900px;
  width: 100%;
  height: auto;
}

.historic-sec .inner-box .image-box {
  max-width: 345px;
  max-height: 400px;
}

.historic-sec ul {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
  margin-top: 20px;
}

.historic-sec ul li {
  color: #0071b8;
  font-size: 15px;
  font-weight: 500;
}

.historic-sec ul li svg {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}

/* historic end */

/* footer start */

footer {
  background-color: #eaf6fc;
  padding: 30px 50px;
}

/* footer.scrolled{
  position: fixed;
  width: 100%;
  right: 0;
  left: 0;
  bottom: 0;
  max-width: 1644px;
  margin: auto;
} */

footer .top-box,
footer .bottom-box {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

footer .bottom-box {
  margin-top: 30px;
}

footer .top-box .social-box {
  display: flex;
  gap: 35px;
}

footer .top-box .footer-logo {
  width: 140px;
  height: 50px;
}

footer .top-box .social-box p {
  color: #4f547b;
  font-size: 17px;
  margin: 0;
  font-weight: 700;
}

footer .top-box .social-box .social-link {
  display: flex;
  gap: 40px;
}

footer .top-box .social-box .social-link a {
  width: 18px;
  height: 18px;
  color: #0071b8;
  fill: #0071b8;
}

footer .bottom-box p {
  color: #4f547b;
  font-size: 13px;
  margin: 0;
  line-height: inherit;
}

.footer-link {
  display: flex;
  font-family: sans-serif;
  gap: 30px;
}

.footer-link a {
  color: #4f547b;
  /* text-transform: capitalize; */
  /* text-align: center; */
}

/* footer end */

.max-1300 {
  max-width: 1300px;
  margin: auto;
}

/* statics region start */

.region-page-space {
  padding-top: 30px !important;
}

.map-box {
  position: relative;
}

/* .map-box .card {
  position: absolute;
  top: 50%;
  left: 67%;
  transform: translate(-50%, -50%);
  width: 360px;
  border-radius: 5px;
  padding: 30px;
  border-color: #5182b2;
} */

.map-box .card{
  position: absolute;
  transform: translate(5%, -30%);
  width: 360px;
  border-radius: 5px;
  padding: 30px 20px;
  border-color: #5182B2;
}

.map-box .card::after {
  content: "";
  position: absolute;
  top: 88px;
  left: -11px;
  background-color: #fff;
  width: 20px;
  height: 20px;
  border: 1px solid #5182b2;
  border-right: none;
  border-bottom: none;
  transform: rotate(316deg);
}

.map-box .card h3 {
  font-size: 18px;
  color: #5182b2;
  font-weight: 700;
  /* margin-bottom: 20px; */
}

.map-box .card .detail {
  flex-wrap: nowrap;
  gap: 15px;
  /* margin-bottom: 20px; */
  justify-content: flex-start;
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.map-box .card .detail svg {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.map-box .card .list-detail {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  flex-wrap: wrap;
    margin-top: 20px;
}

.map-box .card .list-detail svg{
  width: fit-content;
}

.map-box .card .btn {
  font-size: 16px;
  margin-top: 20px;
}

.map-box .card .close-btn{
  position: absolute;
  top: -13px;
  right: -13px;
  width: 24px;
  height: 24px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.5);
  border-radius: 50%;
  font-size: 14px;
  cursor: pointer;
}

.region-num-sec .inner-box {
  display: grid;
  grid-template-columns: 48% 48%;
  gap: 50px;
}

.region-num-sec .inner-box .box h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  color: #5182b2;
  margin-bottom: 20px;
}

.region-num-sec .inner-box .box ul li {
  font-size: 24px;
  font-weight: 300;
  color: #5182b2;
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 15px;
}

.region-num-sec .inner-box .box ul li:last-child {
  margin-bottom: 0;
}

.region-num-sec .inner-box .box ul li span {
  min-width: 50px;
  text-align: end;
  display: inline-block;
  font-size: 40px;
  line-height: normal;
  font-weight: 700;
  flex-shrink: 0;
}

.region-num-sec .inner-box .box ul li span.perc {
  min-width: 133px;
}

.region-num-sec .btn {
  width: fit-content;
  font-size: 16px;
  margin-top: 20px;
}

.progress-sec {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 60px;
}

.progress-sec .box {
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  gap: 10px;
}

.progress-sec .box .CircularProgressbar {
  width: 150px;
  height: 150px;
}

.progress-sec .box .CircularProgressbar-path {
  stroke-linecap: unset;
}

.progress-sec .box .CircularProgressbar.green-color .CircularProgressbar-path {
  stroke: #bacf1b;
}

.progress-sec .box .CircularProgressbar.pink-color .CircularProgressbar-path {
  stroke: #e61c63;
}

/* .CircularProgressbar-trail , .CircularProgressbar-path{
  stroke-width: 12px;
} */

.progress-sec .box p {
  font-size: 20px;
  font-weight: 700;
  color: #5182b2;
}

/* statics region end */

/* self assessment start */

.self-asset-sec {
  display: flex;
  gap: 100px;
  justify-content: center;
}

.self-asset-sec .details-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 62%;
}

.self-asset-sec .details-left .btn {
  width: 400px;
}

.self-asset-sec .form-group {
  margin: 20px 0;
}

.self-asset-sec .form-group label {
  display: block;
  font-size: 17px;
}
.self-asset-sec .email-box {
  max-width: 400px;
  width: 100%;
}

.self-asset-sec .form-group input {
  border: none;
  border-bottom: 1px solid #5182b2;
  padding: 5px 5px 5px 15px;
  width: 100%;
}

.self-asset-sec .form-group input::placeholder {
  color: #00000066;
}

.self-asset-sec .accept-req {
  width: 400px;
}

.form-check-input,
.form-check-label {
  cursor: pointer;
}

.self-asset-sec .form-check-input:checked {
  background-color: #e61c63;
  border-color: #e61c63;
}

.self-asset-sec .grade-select-box {
  display: flex;
  gap: 20px;
  margin-top: 50px;
}

.self-asset-sec .grade-select-box .form-check {
  position: relative;
  width: 200px;
  height: 60px;
  border-radius: 5px;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.self-asset-sec .grade-select-box .form-check .form-check-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 60px;
  border-radius: 0;
  border: 2px solid #5182b2;
  background-color: transparent;
  margin: 0;
  padding: 0;
  border-radius: 5px;
  background-image: unset;
}

.self-asset-sec .grade-select-box .form-check .form-check-input:checked {
  background-color: #bacf1b;
}

.self-asset-sec .grade-select-box .form-check label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #5182b2;
  font-weight: 700;
  font-size: 16px;
}

.self-asset-sec .opt-select-box {
  display: flex;
  gap: 50px;
  margin-top: 20px;
}

.self-asset-sec .opt-select-box .form-check {
  width: 220px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  padding: 0;
  background-color: #3bb2f41a;
  display: flex;
  align-items: center;
  justify-content: center;
}

.self-asset-sec .opt-select-box .form-check .form-check-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0;
  margin: 0;
  border: 2px solid #5182b2;
  border-radius: 10px;
  background-color: transparent;
  background-image: unset;
}

.self-asset-sec .opt-select-box .form-check .form-check-input:checked {
  border-color: #bacf1b;
}

.self-asset-sec .opt-select-box .form-check .form-check-label {
  padding: 20px 10px;
  font-size: 22px;
  font-weight: 700;
  color: #302c37;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.self-asset-sec .opt-select-box .form-check .form-check-label .image-box {
  width: 80px;
  height: 80px;
}

.self-asset-sec .caption {
  color: #5182b2;
  font-weight: 700;
  font-size: 24px;
  margin: auto;
  margin-top: 30px;
  max-width: 600px;
  text-align: center;
}

.self-asset-sec .details-right {
  max-width: 425px;
  text-align: center;
}

.self-asset-sec .details-right .image-box {
  max-width: 425px;
  height: auto;
  margin: auto;
  margin-top: 60px;
}

.self-asset-sec .details-right p {
  color: #0071b8;
  font-size: 17px;
  line-height: 24px;
  margin-top: 40px;
}

.self-asset-sec .details-right .btn {
  width: 100%;
  margin-top: 60px;
}

.choose-school-canvas .form-select {
  background-color: #eaf6fc;
  color: #5182b2;
  height: 40px;
  border-color: #5182b2;
  border-radius: 5px;
  margin-bottom: 20px;
  cursor: pointer;
}

.choose-school-canvas .form-check {
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  height: 40px;
  margin: 0;
  margin-bottom: 20px;
  padding: 6px 12px;
  display: flex;
  align-items: center;
}

.choose-school-canvas .form-check .form-check-input {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  margin: 0;
  border-radius: 5px;
  background-image: unset;
}

.choose-school-canvas .form-check .form-check-input:checked {
  background-color: #f8bd01;
}

.choose-school-canvas .form-check .form-check-label {
  position: absolute;
  top: 10px;
}

.self-asset-sec .sub-capion {
  color: #302c37;
  max-width: 650px;
  margin: 0;
  text-align: center;
}

.result-sec {
  padding: 100px 0;
}

.result-sec-text {
  /* max-width: 400px; */
  margin: auto;
  margin-top: 50px;
}

.result-sec-text p {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 40px;
  color: #5182b2;
}

.result-sec-text .action-btn {
  display: flex;
  gap: 15px;
  justify-content: center;
}

.result-sec-text .action-btn .btn {
  font-size: 16px;
}
/* self assessment end */

.btn:hover {
  color: inherit !important; 
  background-color: inherit !important;
  border-color: inherit !important;
}

/*test*/

.test-lang-sec{
  padding-top: 25px;
}

.test-lang-sec .count-slider {
  max-width: 550px;
  margin: auto;
  width: 100%;
}

.test-lang-sec .top-line-box .right-side{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
}


/* .test-lang-sec .count-slider .swiper-wrapper{
  max-width: 450px;
  margin: auto;
  overflow: hidden;
} */

/* .test-lang-sec .count-slider .swiper-wrapper .swiper-slide{
  width: 80px !important;
} */

.test-lang-sec .mySwiper-user .swiper-slide .count-number {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 2px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  cursor: pointer;
  background-color: #eaf6fc;
}

.test-lang-sec .mySwiper-user .swiper-slide .count-number p {
  font-size: 16px;
  font-weight: 700;
}

.test-lang-sec .mySwiper-user .swiper-slide.swiper-slide-thumb-active .count-number {
  background-color: #F8BD01;
}

.test-lang-sec .swiper-button-prev {
  left: -7px;
}

.test-lang-sec .swiper-button-next {
  right: -7px;
}

.test-lang-sec .swiper-button-prev:after,
.test-lang-sec .swiper-button-next.swiper-button-next:after {
  color: #000;
  font-size: 18px;
}

.test-lang-sec .slider-box {
  position: relative;
}

.test-lang-sec .slider-box .top-line-box{
  display: grid;
  align-items: center;
  grid-template-columns: 22% 56% 22%;
}

.test-lang-sec .slider-box .top-line-box h1{
  color: #5182b2;
  font-size: 22px;
}

.test-lang-sec .slider-box .time {
  /* position: absolute;
  right: 0;
  top: 15px; */
  display: flex;
  align-items: center;
  gap: 15px;
  width: fit-content;
  font-size: 20px;
  color: #000;
}

.question-area .image-box-q{
  max-width: 100%;
  height: 250px;
  margin: 20px 0;
}

.question-area .image-box-q img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.question-slider {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 60vh;
}

.question-slider .intro-box{
  margin-bottom: 10px;
}

.question-slider .intro-box h3 {
  color: #5182b2;
  /* margin-bottom: 10px; */
}

.question-slider .intro-box ul {
  padding-left: 25px;
}

.question-slider .intro-box ul li {
  list-style: auto;
  font-size: 22px;
}

.question-slider .question-box {
  border: 1px solid #e61c63;
  border-radius: 10px;
  padding: 20px 15px;
  /* margin-top: 30px; */
}

.question-slider .question-box h3 {
  font-size: 24px;
  font-weight: 700;
  color: #e61c63;
  margin-bottom: 10px;
}

.question-slider .question-box p {
  color: #000;
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 15px;
}

.question-slider .question-box div b{
  text-shadow: 0 0 0.8px black,    0 0 0.8px black,     0 0 0.8px black ;
}

.question-slider .question-box p:last-child {
  margin: 0;
}

.question-slider .answer-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 60px;
  margin-top: 30px;
}

.question-slider .answer-box .btn{
  max-width: 300px;
  width: 100%;
}

.question-slider .radio-box {
  display: flex;
  align-items: center;
  gap: 20px;
}
.question-slider .test{
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
  gap: 20px;
}
.question-slider .test li{
  width: 30%;
}
.question-slider .test li div{
  margin-bottom: 20px;
}
.question-slider .test li div b{
  text-shadow: 0 0 0.8px black,    0 0 0.8px black,     0 0 0.8px black ;
}
.radio-box-list .image-box-q{
  height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #707070;
}
.radio-box-list .image-box-q img{
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.radio-box-list label{
  width: 100%;
}

.radio-box-list .form-check {
  width: 43%;
  user-select: none;
}
.radio-box.radio-box-list{
  flex-wrap: wrap;
  column-gap: 100px;
  margin-top: 60px;
  justify-content: center;
}
.question-slider .radio-box label {
  font-weight: 700;
  color: #000;
  font-size: 20px;
  user-select: none;
}

.question-slider .radio-box .form-check-input {
  /* margin: 5px; */
  border: 1px solid #f8bd01;
  background-image: unset;
  float: left;
  margin-left: -1.5em;
}

.question-slider .radio-box .form-check-input:checked {
  background-color: #f8bd01;
  border: 3px solid #fff;
  outline: 1px solid #f8bd01;
}

/* parent details pages start */

.parent-details-page .p-student-details .right-side .user-activity-box .table-box table {
  margin: 0;
}

.parent-details-page .parent-main-box {
  display: flex;
}

.parent-details-page .sec-heading {
  text-align: start;
}

.parent-details-page .parent-main-box .left-side {
  border-right: 1px solid #5182b2;
  padding-right: 30px;
  margin-right: 30px;
  width: 370px;
  flex-shrink: 0;
}

.parent-details-page .left-side .inner-box .title-top {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
}

.parent-details-page .left-side .inner-box .title-top .inner-top {
  display: flex;
  gap: 10px;
  align-items: center;
}

.parent-details-page .left-side .inner-box .title-top h2 {
  font-size: 22px;
  color: #5182b2;
}

.parent-details-page .left-side .inner-box .box {
  margin-top: 50px;
}

.parent-details-page .left-side .inner-box .box:first-child {
  margin-top: 0px;
}

.parent-details-page .left-side .inner-box .box ul {
  margin-top: 15px;
}

.parent-details-page .left-side .inner-box .box ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.parent-details-page .left-side .inner-box .box ul li:last-child {
  margin-bottom: 0;
}

.parent-details-page .left-side .inner-box .box ul li span {
  font-size: 16px;
  color: #5182b2;
}

.parent-details-page .left-side .inner-box .box ul li a {
  font-size: 16px;
  color: #5182b2;
}

.parent-details-page .right-side .in-left .title-top {
  display: flex;
  gap: 50px;
}

.parent-details-page .right-side .in-left .title-top h3 {
  color: #2fa4dd;
  font-size: 24px;
  font-weight: 700;
}

.parent-details-page .right-side {
  display: grid;
  grid-template-columns: 60% 40%;
  gap: 20px;
  width: 100%;
}

.parent-details-page .right-side table tr th,
.parent-details-page .right-side table tr td {
  border: none;
  font-size: 24px;
  font-weight: 700;
  color: #5182b2;
}

.p-school-details .right-side table tr th:first-child {
  color: #f8bd01;
}

.parent-details-page .right-side table tr th:first-child,
.parent-details-page .right-side table tr td:first-child {
  max-width: 250px !important;
  min-width: 250px !important;
  padding-right: 20px;
}

.parent-details-page .right-side table tr th:last-child,
.parent-details-page .right-side table tr td:last-child {
  max-width: 235px !important;
  min-width: 235px !important;
  text-align: center;
}

.parent-details-page .right-side table tr td.green-color {
  color: #bacf1b;
}

.parent-details-page .right-side table tr td.pink-color {
  color: #e61c63;
}

.parent-details-page .right-side .in-right h2 {
  font-size: 16px;
  color: #5182b2;
  text-align: center;
  margin-bottom: 30px;
}

.parent-details-page .right-side .in-right .card-box {
  margin-bottom: 50px;
  border: 1px solid #f8bd01;
  padding: 10px;
  height: fit-content;
}

.parent-details-page .right-side .in-right .card-box:last-child {
  margin-bottom: 0px;
}

.parent-details-page .right-side .in-right .card-box h3 {
  font-size: 24px;
  color: #5182b2;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}

.parent-details-page .right-side .in-right .card-box ul li {
  font-size: 24px;
  color: #5182b2;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  font-weight: 700;
}

.parent-details-page .right-side .in-right .card-box ul li:last-child {
  margin-bottom: 0;
}

.choose-school-canvas .form-group input {
  height: 40px;
}

.choose-school-canvas .btn {
  min-height: 40px;
  padding: 5px 15px;
  font-size: 14px;
  color: #5182b2;
  /* border-color: #5182B2; */
}

.or-text {
  font-size: 18px;
  font-weight: 700;
  margin: 20px 0;
  color: #5182b2;
}

.choose-school-canvas .select-box {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.choose-school-canvas .select-box label {
  font-size: 18px;
  color: #5182b2;
}

.choose-school-canvas .select-box .form-select {
  width: 170px;
}
/* parent details pages end */

/* parent student details page start */

.parent-details-page .p-student-details .right-side table tr th .table-title {
  color: #f8bd01;
}

.parent-details-page .p-student-details .right-side table tr th:first-child {
  font-size: 55px;
  text-align: start;
}

.parent-details-page .p-student-details .right-side table tr th,
.parent-details-page .p-student-details .right-side table tr td {
  font-size: 16px;
  text-align: center;
  min-width: 120px !important;
  max-width: 120px !important;
}

.parent-details-page .p-student-details .right-side table tr td:first-child {
  text-align: start;
}

.parent-details-page .p-student-details .right-side table tr {
  border-bottom: 1px solid #5182b2;
}

.parent-details-page .p-student-details .right-side table tr:last-child {
  border-bottom: none;
}

.parent-details-page .p-student-details .right-side {
  display: block;
}

.parent-details-page .p-student-details .right-side .sec-heading {
  text-align: center;
}

.parent-details-page .p-student-details .right-side .user-activity-box {
  display: grid;
  grid-template-columns: auto auto;
  gap: 100px;
}

.parent-details-page .p-student-details .right-side .user-activity-box .table-box {
  margin-bottom: 50px;
}

.parent-details-page .p-student-details .right-side .user-activity-box .table-box:last-child {
  margin-bottom: 0px;
}

.parent-main-box .nav-tabs {
  justify-content: space-evenly;
;
  border-color: #9b4692;
}

.parent-main-box .nav-tabs .nav-item .nav-link {
  border: none;
  border-bottom: 3px solid transparent;
  font-size: 24px;
  color: #5182b2;
}

.parent-main-box .nav-tabs .nav-item .nav-link:hover {
  border: none;
  border-bottom: 3px solid #9b4692;
}

.parent-main-box .nav-tabs .nav-item .nav-link.active {
  border: none;
  border-bottom: 3px solid #9b4692;
}

.parent-main-box .tab-content .tab-pane {
  padding: 40px 0 0;
}

.parent-main-box .tab-content .small-title {
  font-size: 24px;
  font-weight: 700;
  color: #5182b2;
  text-align: center;
  margin-bottom: 20px;
}

.parent-main-box .tab-content .activity-box {
  display: grid;
  grid-template-columns: 50% 50%;
}

.parent-main-box .tab-content .activity-box .inner-box:nth-child(1) {
  padding-right: 20px;
  border-right: 1px solid #5182b24d;
}

.parent-main-box .tab-content .activity-box .inner-box:nth-child(2) {
  padding-left: 20px;
}

.parent-main-box .tab-content .activity-box table tr th:first-child {
  font-size: 16px;
}

.parent-main-box .tab-content .activity-box table tr th,
.parent-main-box .tab-content .activity-box table tr td {
  font-size: 14px !important;
}

.parent-main-box .tab-content .eye-icon svg {
  width: 25px;
  /* height: fit-content; */
}

.parent-main-box .tab-content .activity-box table tr td:last-child {
  min-width: 90px !important;
  max-width: 90px !important;
}

.parent-main-box .tab-content .activity-box table {
  margin: 0;
}

.parent-main-box .tab-content .activity-box .inner-box .box {
  border-bottom: 1px solid #5182b24d;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.parent-main-box .tab-content .activity-box .inner-box .box:last-child {
  border: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.parent-main-box .tab-content .eye-icon {
  display: none;
  cursor: pointer;
}

.parent-main-box .tab-content .email-show {
  display: none;
  word-break: break-all;
}

.parent-main-box .tab-content .eye-icon.show {
  display: block;
}

.parent-main-box .tab-content .email-show.show {
  display: block;
}

.cursor-pointer {
  cursor: pointer;
}

.chart-image-small {
  height: 245px;
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: center;
}

.chart-image-box {
  height: fit-content;
}

.chart-image-small img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.education-map-box .sub-title {
  font-size: 24px;
  font-weight: 700;
  color: #5182b2;
  margin-bottom: 30px;
  text-align: center;
}

.education-map-box .chart-image-small {
  justify-content: center;
  height: 350px;
  gap: 50px;
}

.education-map-box .chart-image-small img {
  width: fit-content;
}

.education-map-box .chart-image-small .chart-image-box:nth-child(1){
  height: 200px;
}

.education-map-box .chart-image-small .chart-image-box:nth-child(2){
  height: 150px;
}

.education-map-box .d-grid{
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 15px;
}

.education-map-box .d-grid canvas{
  width: 100%;
}

.education-map-box .in-sec {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.education-map-box .in-sec .bar-image {
  max-width: 650px;
  width: 100%;
  text-align: center;
}

.education-map-box .in-sec .bar-image img {
  width: fit-content;
  height: auto;
  object-fit: contain;
  margin: auto;
}

.education-map-box .in-sec .owl-image {
  max-width: 350px;
  width: 100%;
  text-align: center;
}

.education-map-box .in-sec .owl-image p {
  color: #0071b8;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 30px;
}

.education-map-box .in-sec .owl-image img {
  width: 180px;
  height: auto;
  object-fit: contain;
}

.prob-succes-sec .sub-title {
  font-size: 24px;
  font-weight: 700;
  color: #5182b2;
  margin-bottom: 20px;
  text-align: center;
}

.prob-succes-sec .image-box {
  max-width: 700px;
  margin: auto;
}

.prob-succes-sec .image-box img {
  margin-bottom: 100px;
}

.prob-succes-sec .in-sec {
  display: flex;
  align-items: flex-end;
}

.prob-succes-sec .in-sec .owl-image {
  width: 215px;
  height: fit-content;
}

.breadcumb {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
}

.breadcumb li {
  color: #5182b2;
  font-size: 16px;
  margin: 15px 0;
}

.breadcumb li span {
  margin-right: 10px;
}

.breadcumb li:first-child span {
  display: none;
}

.parent-main-box .tab-content .school-analy-sec .small-title {
  text-align: start;
}

.parent-main-box .summary-box {
  display: flex;
  align-items: flex-end;
}

.parent-main-box .summary-box .text p {
  margin-bottom: 15px;
  font-size: 16px;
  color: #5182b2;
}

.parent-main-box .summary-box .owl-image {
  width: 215px;
  height: fit-content;
  flex-shrink: 0;
}

.parent-main-box .school-analy-sec .chart-image {
  height: 250px;
  width: fit-content;
}

.parent-main-box .school-analy-sec .chart-image > div{
  width: 100% !important;
  height: auto !important;
}

.parent-main-box .school-analy-sec .chart-image .canvasjs-chart-container , .canvasjs-chart-tooltip{
  position: unset;
  width: 450px !important;
  height: 350px !important;
}

.parent-main-box .school-analy-sec .chart-image .canvasjs-chart-container .canvasjs-chart-canvas{
  width: 450px !important;
  height: 350px !important;
}

.canvasjs-chart-container .canvasjs-chart-credit{
  display: none;
}

.parent-main-box .tab-content .small-topbar-area {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.parent-details-page .improve-box {
  margin-top: 30px;
}

.parent-details-page .stn-summary .improve-box table tr {
  border: none !important;
}

.stn-summary .owl-image {
  display: flex;
  gap: 40px;
  margin-top: 30px;
}

.stn-summary .owl-image p {
  color: #5182b2;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.stn-summary .owl-image img {
  width: 175px;
  height: fit-content;
  object-fit: contain;
}

.parent-details-page .p-student-details .right-side .switch-opt table tr {
  border: none;
}

.parent-details-page .p-student-details .right-side .switch-opt table tr td {
  font-weight: 400;
}

.parent-details-page .p-student-details .right-side .switch-opt table tr td:nth-child(2) {
  min-width: 100px !important;
  max-width: 100px !important;
}

.parent-details-page .p-student-details .right-side .switch-opt table tr td:nth-child(3) {
  width: 30px !important;
  min-width: unset !important;
}

.parent-details-page .p-student-details .right-side .switch-opt table tr td .form-switch {
  padding: 0;
  margin: 0;
  width: fit-content;
}

.parent-details-page .p-student-details .right-side .switch-opt table tr td .form-switch .form-check-input {
  padding: 0;
  margin: 0;
}

.parent-details-page .p-student-details .right-side .switch-opt table tr td .form-switch .form-check-input:checked {
  background-color: #bacf1b !important;
  border-color: #bacf1b;
}
/* 
.form-switch .form-check-input:focus{
  background-image: inherit;
} */

.parent-details-page .p-student-details .right-side .switch-opt table tr td .form-switch .form-check-input {
  background-color: #e61c63;
  border-color: #e61c63;
}

.parent-details-page .p-student-details .right-side .switch-opt table tr td .count {
  border: 1px solid #5182b2;
  width: 80px;
  text-align: center;
  padding: 5px 15px;
  display: inline-block;
}

.cold-school-change .box {
  border: none !important;
  margin-left: 15px;
  padding: 0 !important;
}

.cold-school-change .box .label-title {
  font-size: 16px;
  color: #5182b2;
  margin-bottom: 10px;
  text-decoration: underline;
  width: fit-content;
}

.cold-school-change .box .change-box {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.cold-school-change .box .change-box span {
  font-size: 16px;
  color: #5182b2;
  margin-left: 10px;
}

/* parent student details page end */

/* after login offcanvas start */

.after-login .profile-details {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  margin-bottom: 30px;
}

.after-login .profile-details .image-box {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.after-login .profile-details .image-box img {
  border-radius: 50%;
}

.after-login .profile-details .name {
  font-size: 24px;
  font-weight: 700;
  color: #5182b2;
}

.after-login .profile-details .emial-id {
  font-size: 16px;
  color: #5182b2;
}

.after-login .nav-pills {
  flex-wrap: nowrap;
}

.after-login .nav-pills li {
  width: 100%;
}

.after-login .nav-pills li .nav-link {
  width: 100%;
  border: 1px solid #5182b2;
  color: #5182b2;
  background-color: #eaf6fc;
  font-weight: 700;
  border-radius: 0;
}

.after-login .nav-pills li .nav-link.active {
  background-color: transparent;
}

.after-login .tab-content ul li {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
}

.after-login .tab-content ul li:hover {
  background-color: #eaf6fc;
  border-color: #5182b2;
}

.after-login .tab-content ul li a {
  display: flex;
  align-items: center;
  gap: 20px;
  color: #5182b2;
  font-weight: 700;
  font-size: 16px;
}

.after-login .tab-content ul li a svg path {
  stroke: #5182b2;
}

.after-login .tab-content ul li:hover a svg path {
  fill: #5182b2;
}

.after-login .bottom-box {
  position: absolute;
  bottom: 20px;
  width: 80%;
}

.after-login .bottom-box li {
  border-bottom: 1px solid #5182b2;
  cursor: pointer;
  padding: 10px 15px;
}

.after-login .bottom-box li:last-child {
  border: none;
}

.after-login .bottom-box li:hover {
  background-color: #eaf6fc;
}

.after-login .bottom-box li a {
  color: #5182b2;
  display: flex;
  align-items: center;
  gap: 20px;
  /* padding: 10px 15px; */
}
.after-login .bottom-box li div {
  color: #5182b2;
  display: flex;
  align-items: center;
  gap: 20px;
  /* padding: 10px 15px; */
}

.after-login .bottom-box li div i{
  color: #5182B2;
  font-size: 20px;
}

.after-login .bottom-box li .svg-box svg g{
  fill: #5182B2;
}
/* after login offcanvas end */

/* setting page start */

.setting-page {
  display: grid;
  grid-template-columns: 59% 41%;
}

.setting-page .s-service-box {
  border-right: 1px solid #9b4692;
  padding-right: 40px;
  margin-right: 40px;
}

.setting-page .s-service-box .boxes {
  padding-bottom: 40px;
  border-bottom: 2px solid #5182b2;
  margin-bottom: 40px;
}

.setting-page .s-service-box .boxes:last-child {
  border: none;
  padding: 0;
  margin: 0;
}

.setting-page .s-service-box .boxes .box-top{
  font-size: 18px;
  gap: 10px;
}

.setting-page .s-service-box .boxes .topbar {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.setting-page .s-service-box .boxes .topbar p:nth-child(1) {
  font-size: 24px;
  font-weight: 700;
  color: #e61c63;
  max-width: 330px;
}

.setting-page .s-service-box .boxes .topbar p:nth-child(2) {
  font-size: 16px;
  color: #e61c63;
}

.setting-page .s-service-box .boxes .topbar .plus-box {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.setting-page .s-service-box .boxes .topbar .plus-box svg {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.setting-page .s-service-box .boxes .topbar .plus-box span {
  font-size: 16px;
  color: #5182b2;
  text-align: center;
}

.setting-page .s-service-box .topbar-caption {
  font-size: 16px;
  color: #5182b2;
}

.setting-page .s-service-box .table-box {
  margin-top: 20px;
}

.setting-page .s-service-box .table-box table tr {
  border-bottom: 1px solid #5182b2;
}

.setting-page .s-service-box .table-box table tr:last-child {
  border-bottom: none;
}

.setting-page .s-service-box .table-box table tr th,
.setting-page .s-service-box .table-box table tr td {
  font-size: 16px;
  color: #5182b2;
  border: none;
}

.setting-page .s-service-box .table-box table tr td .dots {
  display: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.setting-page .s-service-box .table-box table tr td.green-color .dots {
  background-color: #b1d724;
}

.setting-page .s-service-box .table-box table tr td.pink-color .dots {
  background-color: #e61c63;
}

.setting-page .s-shopping-cart-box .cart-box .box {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 30px;
}

.setting-page .s-shopping-cart-box .cart-box .box .product-title {
  max-width: 430px;
}

.setting-page .s-shopping-cart-box .cart-box .box .product-title h3 {
  font-size: 24px;
  font-weight: 700;
  color: #e61c63;
  margin-bottom: 5px;
}

.setting-page .s-shopping-cart-box .cart-box .box .product-title p {
  font-size: 16px;
  color: #5182b2;
}

.setting-page .s-shopping-cart-box .cart-box .box .form-control {
  width: 60px;
  height: 35px;
  padding: 5px;
  padding-right: 0;
  border-color: #adadad;
  /* border-radius: 0; */
}

.form-control:focus {
  box-shadow: none;
}

.setting-page .s-shopping-cart-box .cart-box .box .price {
  min-width: 100px;
  text-align: end;
  font-size: 20px;
  font-weight: 700;
  color: #5182b2;
}

.setting-page .s-shopping-cart-box .buy-box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-bottom: 40px;
}

.setting-page .s-shopping-cart-box .buy-box .form-group {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 10px;
}

.setting-page .s-shopping-cart-box .buy-box .form-group label {
  font-size: 24px;
  font-weight: 700;
  color: #5182b2;
}

.setting-page .s-shopping-cart-box .buy-box .form-group .form-control {
  width: 160px;
  border-radius: 5px;
}

.setting-page .s-shopping-cart-box .buy-box .form-group p {
  text-align: end;
  border-color: #5182b2;
}

.setting-page .s-shopping-cart-box .buy-box .btn {
  color: #5182b2;
  padding: 10px 20px;
  width: 150px;
  border-radius: 5px;
}

.setting-page .s-shopping-cart-box .buy-box .form-group input {
  border-color: #b1d724 !important;
}

.setting-page .s-shopping-cart-box .text-image {
  display: flex;
  align-items: center;
  gap: 20px;
}

.setting-page .s-shopping-cart-box .text-image .image-box {
  width: 290px;
  height: 290px;
  flex-shrink: 0;
}

.setting-page .s-shopping-cart-box .text-image p {
  font-size: 17px;
  line-height: 32px;
  text-align: center;
  color: #5182b2;
}

/* setting page end */

.region-select {
  /* border: 1px solid #e61c63; */
  border-radius: 5px;
  padding: 0px 10px !important;
  gap: 10px;
  border: none !important;
  max-width: unset !important;
  min-width: 300px !important;
  width: fit-content !important;
}

.region-select:hover {
  border: none;
}

.region-select span {
  color: #5182b2;
  font-size: 24px;
}

.region-select select {
  background-image: none;
  width: fit-content;
  padding: 0;
  font-size: 24px;
  overflow-y: auto;
  /* background-position: right -24px center; */
  /* appearance: auto; */
}

.region-select select option {
  font-size: 16px;
}

.region-select:not(.btn-check) + .btn:active {
  border: none !important;
}

/* force test start */

.forcetest-inst .instruction-box {
  display: grid;
  grid-template-columns: 35% 60%;
  align-items: center;
  gap: 20px;
}

.forcetest-inst .instruction-box .image-box {
  grid-row: 1 / span 2;
  max-width: 290px;
  margin: auto;
}

.forcetest-inst .instruction-box .box {
  background-color: #eaf6fc;
  padding: 40px 30px;
  border: 1px solid #302c37;
  height: fit-content;
  border-radius: 35px;
  position: relative;
}

.forcetest-inst .instruction-box .box:nth-child(2) {
  border-bottom-left-radius: 0;
}

.forcetest-inst .instruction-box .box:nth-child(3) {
  border-top-left-radius: 0;
}

.forcetest-inst .instruction-box .box::after {
  content: "";
  position: absolute;
  left: -20px;
  background-color: #eaf6fc;
  border: 1px solid #302c37;
  width: 40px;
  height: 40px;
}

.forcetest-inst .instruction-box .box:nth-child(2)::after {
  content: "";
  bottom: -1px;
  transform: skew(316deg, 0deg);
  border-top: none;
  border-right: none;
}

.forcetest-inst .instruction-box .box:nth-child(3)::after {
  content: "";
  top: -1px;
  transform: skew(44deg, 0deg);
  border-right: none;
  border-bottom: none;
}

.forcetest-inst .instruction-box .box p {
  color: #5182b2;
  font-size: 24px;
  text-align: center;
}

.forcetest-inst .self-asset-sec {
  justify-content: end;
  gap: 50px;
  width: 95%;
}

.forcetest-inst .self-asset-sec .skip {
  color: #5182b2;
  border: 2px solid #5182b2;
  border-radius: 20px;
  padding: 15px;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  width: 275px;
}

/* force test end */

/* leaderboard start */

.progress-ladder-sec .nav-tabs {
  width: 100%;
  gap: 20px;
  border-color: #9b4692;
  flex-wrap: nowrap;
  overflow: auto;
  overflow-x: hidden;
  flex-wrap: nowrap;
  overflow: auto;
  overflow-y: hidden;
}

.progress-ladder-sec .nav-tabs .nav-item .nav-link.active {
  border: none;
  border-bottom: 3px solid #9b4692;
}

.progress-ladder-sec .nav-tabs .nav-item .nav-link {
  border: none;
  border-bottom: 3px solid transparent;
  font-size: 24px;
  color: #5182b2;
  font-weight: 700;
}

.progress-ladder-sec .tab-content {
  padding-top: 30px;
}

.progress-ladder-sec .tab-content .tab-in-box {
  margin-bottom: 30px;
  width: 75%;
}

.progress-ladder-sec .tab-content .tab-in-box:last-child {
  margin: 0;
}

.progress-ladder-sec .tab-content .tab-in-box h3 {
  color: #5182b2;
  font-size: 24px;
  text-decoration: underline;
  margin-bottom: 15px;
}

.progress-ladder-sec .tab-content .tab-in-box ul li {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
}

.progress-ladder-sec .tab-content .tab-in-box ul li p {
  color: #5182b2;
  font-size: 24px;
}

/* leaderboard end */


/* student recap start */

.recap-sec .inner-box{
  align-items: center;
}

.recap-sec .detail-sec{
  text-align: center;
}

.recap-sec .sub-font{
  font-size: 24px;
  font-weight: 700;
}

.recap-sec .detail-sec h2{
  color: #5182B2;
  margin-bottom: 15px;
}

.recap-sec .detail-sec h3{
  color: #E61C63;
  margin-bottom: 25px;
}

.recap-sec .detail-sec p{
  color: #5182B2;
}

.recap-sec .detail-sec .image-box{
    max-width: 440px;
    width: 100%;
    margin:  80px auto 0;
}

.recap-sec .test-list li{
  margin-bottom: 20px;
}

.recap-sec .test-list li .list-box{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-radius: 15px;
  border: 2px solid #BACF1B;
  background-color: #f8fae8;
}

.recap-sec .test-list li .list-box .name {
  display: flex;
  align-items: center;
  gap: 20px;
}

.recap-sec .test-list li .list-box .name .image-box{
  width: 50px;
  height: 50px;
}

.recap-sec .test-list li .list-box .name h3{
  font-size: 24px;
  color: #5182B2;
  font-weight: 700;
}

.recap-sec .test-list li .list-box i{
  font-size: 38px;
  color: #BACF1B;
}

.recap-sec .test-list li .list-box p{
  font-size: 24px;
  color: #5182B2;
}

.recap-sec .info-box{
  font-size: 24px;
  color: #5182B2;
  font-weight: 700;
  text-align: center;
  border: 2px solid #BACF1B;
  border-radius: 15px;
  padding: 15px;
  margin-top: 40px;
}
/* student recap end */

/* lesson done start */

.lesson-done .details-left{
  text-align: center;
}

.lesson-done .details-left p{
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 30px;
}

.lesson-done .details-right .image-box{
  max-width: 550px;
  width: 100%;
  margin: 0 auto 50px;
}

.lesson-done .details-right p{
  font-size: 17px;
  text-transform: capitalize;
  text-align: center;
  line-height: 36px;
  font-weight: 700;
}
/* lesson done end */

/* intro topic start */

.intro-topic .details-left p{
  font-size: 24px;
  color: #302c37;
  font-weight: 700;
  margin-bottom: 50px;
}

.intro-topic .details-left .video-box{
  width: 100%;
  height: 400px;
  text-align: center;
  margin-bottom: 50px;
}

.intro-topic .details-left .video-box video{
  width: 80%;
  height: 80%;
  margin: auto;
}

.intro-topic .details-left .image-box{
  max-width: 350px;
  width: 100%;
  margin: auto;
}

.intro-topic .details-right{
  position: sticky;
  top: 0;
}

.intro-topic .details-right .image-box{
  max-width: 550px;
  width: 100%;
  margin: 0 auto 50px;
}

.intro-topic .details-right .btn{
  max-width: 320px;
  width: 100%;
}

/* intro topic end */


/* test css start */

.drag-correct-order .drag-correct-box {
  margin-top: 30px;
}

.drag-correct-order .drag-correct-box .drag-box{
  padding: 15px 20px;
  border: 1px solid #E61C63;
  border-radius: 10px;
  margin-bottom: 15px;
}

.drag-correct-order .drag-correct-box .drag-box:last-child{
  margin: 0;
}

.drag-correct-order .drag-correct-box .drag-box p{
  color: #000;
}

.drag-drop-order .select-word{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 30px;
}

.drag-drop-order .select-word span{
  color: #302C3799;
}

.drag-drop-order .drag-drop-box .drag-box{
  border: 1px solid #E61C63;
  border-radius: 10px;
  padding: 15px 20px;
}

.drag-drop-order .drag-drop-box{
  gap: 20px 0;
  margin-top: 50px;
}

.drag-drop-order .drag-drop-box .drag-box p{
  color: #e61c63;
}

.drag-drop-order .drag-drop-box .drag-box .select-drop{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 15px;
}

.drag-drop-order .drag-drop-box .drag-box .select-drop span{
  font-size: 20px;
  font-weight: 700;
  padding: 5px 10px;
  color: #5182B2;
  border: 1px solid #5182B2;
}

.drag-add-field .drag-drop-box .in-title{
  font-weight: 300;
  margin-top: 30px;
  margin-bottom: 30px;
}

.drag-add-field .drag-drop-box .in-title span{
  font-weight: 700;
}

.drag-add-field .drag-drop-box .drag-box{
  border: 1px solid #E61C63;
  border-radius: 10px;
  padding: 15px 20px;
}

.drag-add-field .drag-drop-box .drag-box p{
  color: #E61C63;
  margin-bottom: 15px;
}

.drag-add-field .drag-drop-box .drag-box .select-drop{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 15px;
}

.drag-add-field .drag-drop-box .drag-box .select-drop span{
  font-size: 20px;
  font-weight: 700;
  padding: 5px 10px;
  color: #5182B2;
  border: 1px solid #5182B2;
}

/* .text-answer-box p{
  color: #000;
  margin: 30px 0;
} */

.form-floating .form-control{
  /* .text-answer-box .form-floating .form-control{ */
  height: 100%;
  max-height: 120px;
  border: 1px solid #e61c63;
  border-radius: 10px;
  color: #000;
  font-size: 20px;
}

.radio-center .radio-box{
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  width: 65%;
  margin: 25px auto 10px;
}

.radio-center .radio-box .form-check{
  width: 31%;
  padding-left: 25px;
}

.radio-center .radio-box .image-label{
  width: 100%;
}

.radio-center .radio-box .image-label img{
  width: 100%;
  height: 200px;
  border: 1px solid #e61c63;
}

.multi-maths .in-title{
  color: #302C37;
}

.multi-maths .multi-box{
  margin-top: 40px;
}

.multi-maths .multi-box .flex-title{
  gap: 5px;
}

.multi-maths .multi-box .box h3{
  color: #5182B2;
}

.multi-maths .multi-box .box i{
  font-size: 24px;
}

.multi-maths .multi-box .box .radio-box .form-check{
  width: 46%;
}

.multi-maths .multi-box .input-box{
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: center;
}

.multi-maths .multi-box .input-box .form-control{
  border-color: #e61c63;
  width: 100px;
}

.student-main .inner-box{
  display: grid;
  grid-template-columns: 49% 49%;
  gap: 30px;
  height: 100%;
}

.student-main .inner-box .details-left{
  border-right: 1px solid #9B4692;
  padding-right: 30px;
}

.student-main .table-box tr td , .student-main .table-box tr th{
  border: none;
}

.student-main .table-box tr .dots ul{
  padding-left: 30px;
}

.student-main .table-box tr .dots ul li{
  list-style: disc;
  padding: 0;
  margin: 0;
}

.student-main .progress-sec{
  grid-template-columns: auto auto;
}

.student-main .yellow-btn{
  margin: 40px auto 0;
  max-width: 400px;
  width: 100%;
}

.student-main .select-btn{
  font-size: 16px;
  padding: 15px 20px;
  border: 1px solid #9B4692;
  color: #9B4692;
  max-width: 400px;
  width: 100%;
  text-align: center;
  font-weight: 700;
  margin: 40px auto 0;
  border-radius: 5px;
  height: fit-content;
}

.p-component{
    font-family: "Comfortaa", "cursive";
}

.map-modal{
  max-width: 970px;
  width: 100% !important;
  padding: 0 20px;
} 

.map-modal .p-dialog-header .p-dialog-title{
  font-size: 24px;
}

.map-modal .map-box svg{
  width: 100%;
  max-height: 500px;
}

.praha-map-box{
  display: absolute;
}

.map-modal .p-dialog-content{
  overflow: visible;
  overflow-x: auto;
  overflow: visible;
}

.road-map-box {
  max-width: 716px;
  margin: auto;
  overflow: auto;
  /* height: 67.2vh; */
  padding-right: 10px;
}

/* .road-map-box.scrolled{
  margin: 0 auto 150px;
  overflow: auto;
  height: 50.2vh;
} */


.road-map-box .box{
  margin-bottom: 50px !important;
  width: 200px;
  height: auto;
  position: relative;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: auto;
}

.road-map-box .first-box{
  background-color: #EAF6FC;
  border: 1px solid #5182B2;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
  gap: 10px;
  margin: auto;
  padding: 15px 20px;
  position: relative;
}

/* .road-map-box .first-box::after{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  border-right: 2px solid #BACF1B;
  border-top: 2px solid #BACF1B;
  width: 200px;
  height: 100%;
  transform: translate(147px,50%);
  border-top-right-radius: 25px;
} */

.road-map-box h2{
  font-size: 16px;
  color: #302C37;
  text-align: center;
}

.road-map-box .icon-box svg{
  width: 30px;
  height: 30px;
}

.road-map-box .box.bg-danger{
  border-color: #ff0000;
}

.road-map-box .box.bg-danger .icon-box svg path{
  stroke: #fff;
}

.road-map-box .right-box{
  background-color: #EAF6FC;
  border: 1px solid #5182B2;
  border-radius: 50px;
}

.road-map-box .left-box{
  background-color: #BACF1B4D;
  border: 1px solid #BACF1B;
  border-radius: 50px;
}

/* .road-map-box .left-box::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  border-left: 2px solid #BACF1B;
  border-top: 2px solid #BACF1B;
  width: 475.5px;
  height: 100%;
  transform: translate(75px,-100%);
  border-top-left-radius: 25px;
} */

/* .road-map-box .left-box::after{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  border-right: 2px solid #BACF1B;
  border-top: 2px solid #BACF1B;
  width: 475.5px;
  height: 100%;
  transform: translate(150px,50%);
  border-top-right-radius: 25px;
} */

.road-map-box .box:nth-last-child(3):after{
  display: none;
}

.road-map-box .verification-box{
  background-color: #FBDE80;
  border: 1px solid #BACF1B;
  border-radius: 50px;
  margin: auto !important;
  position: relative;
  width: 150px;
  padding: 15px 20px;
  height: fit-content !important;
  margin-bottom: 50px !important;
}

/* .road-map-box .verification-box::before{
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  border-right: 2px solid #BACF1B;
  border-top: 2px solid #BACF1B;
  width: 200px;
  height: 162%;
  transform: translate(-126px,-66px);
  border-top-right-radius: 25px;
} */

.road-map-box .bonus-task{
  text-align: center;
}


/* kishor csss */
.up-heading-status{
  border-bottom: 1px solid #9B4692;
  text-align: center;
  margin-bottom: 30px;
}
.up-heading-status h2{
  color: #5182B2;
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 10px;
}
.traning-video-road-map{
  margin-top: 80px;
}
/* .road-map-box .box:nth-child(2), .road-map-box .box:nth-child(9) {
  margin-left: 20%;
}
.road-map-box .box:nth-child(3), .road-map-box .box:nth-child(10) {
  margin-left: 10%;
}
.road-map-box .box:nth-child(4), .road-map-box .box:nth-child(11) {
  margin-left: 25%;
}
.road-map-box .box:nth-child(5), .road-map-box .box:nth-child(12) {
  margin: auto;
}
.road-map-box .box:nth-child(6),.road-map-box .box:nth-child(13) {
  margin-right: 20%;
}
.road-map-box .box:nth-child(7), .road-map-box .box:nth-child(14) {
  margin-right: 10%;
}
.road-map-box .box:nth-child(8), .road-map-box .box:nth-child(15) {
  margin-right: 25%;
} */

.road-map-box .box:nth-child(8n+2){
  margin-left: 20%;
}
.road-map-box .box:nth-child(8n+3){
  margin-left: 10%;
}
.road-map-box .box:nth-child(8n+4) {
  margin-left: 25%;
}
.road-map-box .box:nth-child(4n+5) {
  margin: auto;
}
.road-map-box .box:nth-child(8n+6){
  margin-right: 20%;
}
.road-map-box .box:nth-child(8n+7) {
  margin-right: 10%;
}
.road-map-box .box:nth-child(8n+8) {
  margin-right: 25%;
}

/* .road-map-box .box:nth-child(8n+2)::after{
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  border-right: 5px solid #dcdcdc;
  height: 54px;
  width: 2px;
  transform: skew(344deg, 0deg);
}

.road-map-box .box:nth-child(8n+3)::after{
  content: '';
  position: absolute;
  top: 100%;
  left: 65%;
  border-right: 5px solid #dcdcdc;
  height: 54px;
  width: 2px;
  transform: skew(17deg, 0deg);
}

.road-map-box .box:nth-child(8n+4)::after{
  content: '';
  position: absolute;
  top: 100%;
  left: 60%;
  border-right: 5px solid #dcdcdc;
  height: 54px;
  width: 2px;
  transform: skew(17deg, 0deg);
}

.road-map-box .box:nth-child(4n+5)::after{
  content: '';
  position: absolute;
  top: 100%;
  left: 72%;
  border-right: 5px solid #dcdcdc;
  height: 54px;
  width: 2px;
  transform: skew(17deg, 0deg);
} */

.payment-modal .StripeElement{
  border: 1px solid #adadad;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.payment-detail-modal .modal-dialog{
  max-width: 800px;
  padding: 0 20px;
}

.payment-detail-modal h2{
  font-size: 18px;
  font-weight: 700;
}

.payment-detail-modal .details-box .box{
  display: flex;
  margin-bottom: 15px;
  gap: 10px;
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 15px;
}

.payment-detail-modal .details-box .box:last-child{
  margin-bottom: 0;
  border: none;
  padding: 0;
}

.payment-detail-modal .details-box .box .left{
  flex-shrink: 0;
  width: 30%;
}

.payment-detail-modal .details-box .box .left h3{
  font-size: 16px;
  font-weight: 700;
}

.payment-detail-modal .details-box .box .right p{
  font-size: 16px;
  font-weight: 400;
  color: #000;
}

.true-answer{
  margin-top: 40px;
  border: 1px solid #adadad;
  padding: 15px;
  border-radius: 10px;
}

.true-answer h5{
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}

.reset-password .details-left form{
  width: 65%;
  margin: auto;
}

.reset-password .details-left form .btn{
  padding: 8px 20px;
}

.full-detail-radio {
  display: flex;
  gap: 0px;
}

.full-detail-radio .form-check{
  padding: 0;
  font-size: 22px;
  font-weight: 700;
  position: relative;
  padding-right: 15px;
  margin-right: 5px;
}

.full-detail-radio .form-check:last-child{
  padding: 0;
  margin: 0;
}

.full-detail-radio .form-check:last-child::after{
  display: none;
}

.full-detail-radio .form-check::after{
  content: '/';
  font-size: 18px;
  color: #000;
  position: absolute;
  top: 0;
  right: 0;
}

.full-detail-radio .form-check input{
  display: none;
}

.full-detail-radio .form-check input:checked + label{
  color: #5182b2;
}

pre{
  overflow: hidden;
  white-space: pre-line;
  font-family: "Comfortaa", "cursive" !important;
}