@media only screen and (max-width: 1799px) {
    .progress-sec {
        padding-top: 20px;
    }
    .subdvision-page {
        padding-top: 30px;
    }
}

@media only screen and (max-width: 1599px) {
    .font-24-semibold {
        font-size: 18px;
    }
    .region-select span {
        font-size: 18px;
    }
    .region-select select {
        font-size: 18px;
    }
    .education-map-box .sub-title {
        font-size: 18px;
    }
    .parent-main-box .tab-content .activity-box table tr th,
    .parent-main-box .tab-content .activity-box table tr td {
        font-size: 12px !important;
    }
    .parent-main-box .tab-content .activity-box table tr td:last-child {
        min-width: 60px !important;
        max-width: 60px !important;
    }
    .parent-details-page .right-side .in-left .title-top {
        gap: 30px;
    }
    .home-banner,
    .region-page,
    .result-sec,
    .knowledge-page,
    .test-lang-sec,
    .parent-details-page {
        min-height: calc(100vh - 92.18px);
    }
    .story-padding {
        padding-top: 100px;
        padding-bottom: 75px;
    }

    .apr-padding {
        padding-bottom: 100px;
        padding-top: 75px;
    }
    .historic-sec h2,
    .sec-heading {
        font-size: 30px;
        margin-bottom: 15px;
    }
    .subdvision-page {
        padding-top: 20px;
    }
    .subdvision-page .sec-heading .form-select {
        font-size: 36px;
    }
    .historic-sec .inner-box .map-box {
        max-width: 700px;
    }
    .region-num-sec .inner-box .box h2 {
        font-size: 18px;
    }
    .region-num-sec .inner-box .box ul li {
        font-size: 18px;
        margin-bottom: 10px;
    }
    .region-num-sec .inner-box .box ul li span {
        font-size: 24px;
    }
    .region-num-sec .btn {
        font-size: 14px;
    }
    .progress-sec .box .CircularProgressbar {
        width: 125px;
        height: 125px;
    }
    .progress-sec .box p {
        font-size: 16px;
    }
    .historic-sec .action-btn .btn {
        font-size: 14px;
        max-width: 230px;
        padding: 10px 30px;
    }
    .region-page-space {
        padding-top: 10px !important;
    }
    .self-asset-sec .grade-select-box {
        margin-top: 20px;
    }
    .self-asset-sec .opt-select-box .form-check .form-check-label .image-box {
        width: 50px;
        height: 50px;
    }
    .self-asset-sec .caption {
        font-size: 18px;
    }
    .self-asset-sec .details-right .image-box {
        max-width: 330px;
    }
    .self-asset-sec .details-right .btn {
        margin-top: 30px;
    }
    .self-asset-sec .opt-select-box .form-check .form-check-label {
        font-size: 18px;
    }
    .self-asset-sec .opt-select-box .form-check {
        width: 190px;
    }
    .btn {
        font-size: 16px;
        /* height: 50px; */
    }
    .question-slider .intro-box ul li,
    .question-slider .question-box p {
        font-size: 18px;
    }
    .parent-details-page .p-student-details .right-side table tr th:first-child {
        font-size: 40px;
    }
    .parent-details-page .p-student-details .right-side .user-activity-box {
        gap: 65px;
    }
    .parent-details-page .right-side table tr th,
    .parent-details-page .right-side table tr td {
        font-size: 18px;
    }
    .parent-details-page .right-side .in-right .card-box h3 {
        font-size: 18px;
    }
    .parent-details-page .right-side .in-right .card-box ul li {
        font-size: 18px;
    }
    .setting-page .s-service-box .boxes .topbar p:nth-child(1) {
        font-size: 20px;
    }
    .setting-page .s-service-box .boxes .topbar .plus-box span {
        font-size: 14px;
    }
    .setting-page .s-service-box .boxes .topbar .plus-box svg {
        width: 25px;
        height: 25px;
    }
    .setting-page .s-service-box .topbar-caption {
        font-size: 14px;
    }
    .setting-page .s-service-box .table-box table tr th,
    .setting-page .s-service-box .table-box table tr td {
        font-size: 14px;
    }
    .setting-page .s-shopping-cart-box .cart-box .box .product-title h3 {
        font-size: 20px;
    }
    .setting-page .s-shopping-cart-box .cart-box .box .product-title p {
        font-size: 14px;
    }
    .setting-page .s-shopping-cart-box .cart-box .box .price {
        font-size: 16px;
    }
    .setting-page .s-shopping-cart-box .buy-box .btn {
        width: 120px;
    }
    .setting-page .s-shopping-cart-box .buy-box .form-group label {
        font-size: 20px;
    }
    .setting-page .s-shopping-cart-box .text-image .image-box {
        width: 250px;
        height: 250px;
    }
    .setting-page .s-shopping-cart-box .text-image p {
        font-size: 14px;
    }
    .login-canvas .offcanvas-header {
        padding: 30px 40px;
    }
    .canvas-title {
        font-size: 16px;
    }
    .choose-school-canvas .form-group input {
        font-size: 12px;
    }
    .choose-school-canvas .select-box label {
        font-size: 14px;
    }
    .choose-school-canvas .form-select {
        font-size: 14px;
    }
    .choose-school-canvas .btn {
        font-size: 12px;
    }
    .after-login .profile-details .name {
        font-size: 20px;
    }
    .after-login .profile-details .emial-id {
        font-size: 14px;
    }
    .after-login .profile-details .image-box {
        width: 80px;
        height: 80px;
    }
    .after-login .tab-content ul li a {
        font-size: 14px;
    }
    .parent-main-box .nav-tabs .nav-item .nav-link {
        font-size: 20px;
    }
    .parent-details-page .p-student-details .right-side table tr th,
    .parent-details-page .p-student-details .right-side table tr td {
        font-size: 14px;
    }
    .parent-main-box .tab-content .small-title {
        font-size: 20px;
    }
    .parent-main-box .tab-content .activity-box table tr th:first-child {
        font-size: 14px;
    }
    .parent-main-box .tab-content .eye-icon svg {
        width: 24px;
        height: fit-content;
    }
    .education-map-box .chart-image-small {
        height: 300px;
    }
    .prob-succes-sec .sub-title {
        font-size: 20px;
    }
    /* .region-num-sec .inner-box .box ul li span.perc{
        min-width: 46px;
    } */
    .forcetest-inst .instruction-box .box p {
        font-size: 20px;
    }
    .forcetest-inst .self-asset-sec .skip {
        font-size: 20px;
    }
    .recap-sec .sub-font {
        font-size: 20px;
    }
    .recap-sec .detail-sec .image-box {
        margin: 50px auto 0;
        max-width: 380px;
    }
    .recap-sec .test-list li .list-box .name .image-box {
        width: 40px;
        height: 40px;
    }
    .recap-sec .test-list li .list-box .name h3 {
        font-size: 22px;
    }
    .recap-sec .test-list li .list-box i {
        font-size: 32px;
    }
    .recap-sec .info-box {
        font-size: 22px;
    }
    .intro-topic .details-left p {
        font-size: 20px;
    }
    .intro-topic .details-right .image-box {
        max-width: 450px;
    }
    .lesson-done .details-right .image-box {
        max-width: 450px;
    }
    .map-modal .map-box svg {
        width: 100%;
        max-height: 400px;
    }

    /* road-map design start  */
    .road-map-box .box {
        height: auto;
        margin-bottom: 35px !important;
    }
    .traning-video-road-map {
        margin-top: 60px;
    }

    /* road map css end */
}

@media only screen and (max-width: 1399px) {
    .text-danger {
        font-size: 14px;
    }
    .pb-50 {
        padding-bottom: 25px;
    }
    .pt-50 {
        padding-top: 25px;
    }
    .plr-50 {
        padding-left: 30px;
        padding-right: 30px;
    }
    .region-page,
    .result-sec,
    .knowledge-page,
    .subdvision-page,
    .test-lang-sec,
    .parent-details-page,
    .forcetest-inst,
    .custom-min-h {
        min-height: calc(100vh - 99.92px);
    }
    .map-modal {
        max-width: 650px;
        width: 100% !important;
        padding: 0 20px;
    }
    .map-modal .map-box svg {
        width: 100%;
        max-height: 300px;
    }
    .map-modal .p-dialog-header .p-dialog-title {
        font-size: 20px;
    }
    .drag-add-field .drag-drop-box .drag-box .select-drop span,
    .drag-drop-order .drag-drop-box .drag-box .select-drop span {
        font-size: 18px;
    }
    .test-lang-sec .slider-box .time {
        font-size: 18px;
    }
    .test-lang-sec .mySwiper-user .swiper-slide .count-number {
        width: 45px;
        height: 45px;
    }
    .question-slider .intro-box h3 {
        font-size: 20px;
    }
    .question-slider .question-box h3 {
        font-size: 20px;
    }
    .radio-center .radio-box {
        width: 80%;
    }
    .question-slider .radio-box label {
        font-size: 15px;
    }
    .pink-label,
    .green-label {
        font-size: 15px;
    }
    .font-24-semibold {
        font-size: 14px;
    }
    .student-main .table-box tr td,
    .student-main .table-box tr th {
        padding: 5px;
    }
    .student-main .yellow-btn,
    .student-main .select-btn {
        max-width: 350px;
    }
    .progress-sec .box .CircularProgressbar {
        width: 100px;
        height: 100px;
    }
    .self-asset-sec .opt-select-box .form-check .form-check-label .image-box {
        width: 30px;
        height: 30px;
    }
    .knowledge-page {
        padding-top: 30px !important;
    }
    .historic-sec .inner-box .map-box {
        max-width: 635px;
    }
    .home-banner,
    .region-page,
    .subdvision-page,
    .result-sec,
    .knowledge-page,
    .test-lang-sec,
    .parent-details-page {
        min-height: calc(100vh - 99.19px);
    }
    header .image-box {
        width: 165px;
    }
    header ul li {
        font-size: 16px;
    }
    .lang-select .form-select {
        font-size: 16px;
    }
    header .lang-select svg {
        height: 25px;
        margin-top: 20px;
        width: 25px;
    }
    header .menu svg {
        width: 20px;
        height: 20px;
    }
    .home-banner .image-box {
        height: 300px;
    }
    .home-banner h1 {
        font-size: 36px;
        margin-bottom: 50px;
    }
    .home-banner p {
        font-size: 15px;
        margin-bottom: 30px;
    }
    .btn {
        font-size: 14px;
    }
    .our-story .image-box {
        width: 180px;
    }
    .descri {
        font-size: 20px;
    }
    .title {
        font-size: 20px;
        margin-bottom: 20px;
    }
    .historic-sec .inner-box .image-box {
        max-width: 340px;
    }
    .historic-sec .inner-box {
        margin-top: 15px;
    }
    .login-canvas .form-group input {
        height: 50px;
        font-size: 14px;
    }
    .apr-section .title {
        margin-bottom: 20px;
    }
    .map-box .card {
        padding: 20px;
    }
    .region-page-space {
        padding-top: 10px !important;
    }

    .self-asset-sec .form-group label {
        font-size: 15px;
    }
    .self-asset-sec .accept-req label {
        font-size: 15px;
    }
    .self-asset-sec .grade-select-box .form-check .form-check-input,
    .self-asset-sec .grade-select-box .form-check {
        width: 170px;
    }
    .self-asset-sec .grade-select-box .form-check label {
        font-size: 14px;
    }
    .self-asset-sec .opt-select-box .form-check {
        width: 190px;
    }
    .self-asset-sec .opt-select-box .form-check .form-check-label {
        font-size: 14px;
    }
    .self-asset-sec .caption {
        font-size: 20px;
    }
    .self-asset-sec .details-right .image-box,
    .self-asset-sec .details-right {
        max-width: 275px;
        margin-top: 0px;
    }
    .self-asset-sec .details-right p {
        font-size: 15px;
    }
    .map-box .card h3 {
        font-size: 16px;
    }
    .map-box .card ul li {
        font-size: 13px;
    }
    .map-box .card .btn {
        font-size: 14px;
    }
    .historic-sec h2,
    .sec-heading {
        font-size: 24px;
        margin-bottom: 15px;
    }
    .subdvision-page .sec-heading .form-select {
        font-size: 28px;
    }
    .question-slider .intro-box ul li,
    .question-slider .question-box p {
        font-size: 16px;
    }
    .parent-details-page .p-student-details .right-side table tr th:first-child {
        font-size: 32px;
    }
    .parent-details-page .p-student-details .right-side table tr th,
    .parent-details-page .p-student-details .right-side table tr td {
        font-size: 14px;
        min-width: 80px !important;
        max-width: 80px !important;
    }
    .parent-details-page .p-student-details .right-side table tr th:first-child {
        font-size: 26px;
    }
    .parent-details-page .parent-main-box .left-side {
        width: 290px;
    }
    .parent-details-page .left-side .inner-box .title-top .inner-top svg {
        width: 25px;
        height: 20px;
    }
    .parent-details-page .left-side .inner-box .title-top h2 {
        font-size: 16px;
    }
    .parent-details-page .left-side .inner-box .box ul li span {
        font-size: 14px;
    }
    .parent-details-page .right-side .in-left .title-top h3 {
        font-size: 18px;
    }
    .parent-details-page .right-side table tr th,
    .parent-details-page .right-side table tr td {
        font-size: 16px;
    }
    .parent-details-page .right-side .in-right .card-box h3 {
        font-size: 16px;
    }
    .parent-details-page .right-side .in-right .card-box ul li {
        font-size: 16px;
    }
    .parent-details-page .right-side table tr th:first-child,
    .parent-details-page .right-side table tr td:first-child {
        max-width: 150px !important;
        min-width: 150px !important;
    }
    .parent-details-page .right-side table tr th:last-child,
    .parent-details-page .right-side table tr td:last-child {
        max-width: 150px !important;
        min-width: 150px !important;
    }
    .parent-details-page .p-student-details .right-side .user-activity-box {
        gap: 30px;
    }
    .setting-page .s-service-box .boxes .topbar p:nth-child(1) {
        font-size: 16px;
    }
    .setting-page .s-shopping-cart-box .cart-box .box .product-title h3 {
        font-size: 16px;
    }
    .setting-page .s-shopping-cart-box .cart-box .box .product-title p {
        font-size: 12px;
    }
    .setting-page .s-shopping-cart-box .buy-box .form-group label {
        font-size: 16px;
    }
    .setting-page .s-shopping-cart-box .buy-box .form-group .form-control {
        width: 130px;
    }
    .setting-page .s-shopping-cart-box .text-image .image-box {
        width: 190px;
        height: 190px;
    }
    .parent-main-box .nav-tabs .nav-item .nav-link {
        font-size: 16px;
    }
    .parent-main-box .tab-content .activity-box table tr th:first-child {
        font-size: 14px;
    }
    .chart-image-small {
        height: 208px;
        gap: 10px;
    }
    .parent-details-page .right-side .activity-box table tr th,
    .parent-details-page .right-side .activity-box table tr td {
        font-size: 12px;
    }
    .parent-details-page .right-side .activity-box table tr th:first-child,
    .parent-details-page .right-side .activity-box table tr td:first-child {
        min-width: 80px !important;
        max-width: 80px !important;
        word-break: break-word;
    }
    .parent-details-page .right-side .activity-box table tr th:last-child,
    .parent-details-page .right-side .activity-box table tr td:last-child {
        min-width: 60px !important;
        max-width: 60px !important;
        font-size: 12px;
    }
    .education-map-box .chart-image-small {
        height: 200px;
    }
    .education-map-box .in-sec .owl-image p {
        font-size: 12px;
    }
    .parent-main-box .summary-box .owl-image {
        width: 150px;
    }
    .parent-main-box .summary-box .text p {
        font-size: 12px;
    }
    .parent-main-box .school-analy-sec .chart-image {
        height: 200px;
    }
    .cold-school-change .box .label-title,
    .cold-school-change .box .change-box span {
        font-size: 12px;
    }
    .cold-school-change .box .change-box button {
        font-size: 12px;
    }
    .parent-details-page .p-student-details .right-side .switch-opt table tr td:nth-child(2) {
        max-width: 60px !important;
        min-width: 60px !important;
    }
    .stn-summary .owl-image img {
        width: 100px;
    }
    .stn-summary .owl-image p {
        font-size: 14px;
    }
    .stn-summary .owl-image {
        gap: 10px;
    }
    /* .forcetest-inst .instruction-box .image-box {
        max-width: 325px;
    } */
    .forcetest-inst .instruction-box .box p {
        font-size: 18px;
    }
    .forcetest-inst .self-asset-sec .skip {
        font-size: 16px;
        width: 198px;
    }
    .forcetest-inst .instruction-box .box {
        padding: 30px;
    }
    .forcetest-inst .instruction-box .image-box {
        max-width: 220px;
    }
    .progress-ladder-sec .nav-tabs .nav-item .nav-link {
        font-size: 18px;
    }
    .progress-ladder-sec .tab-content .tab-in-box h3 {
        font-size: 18px;
    }
    .progress-ladder-sec .tab-content .tab-in-box ul li p {
        font-size: 18px;
    }
    .recap-sec .sub-font {
        font-size: 18px;
    }
    .recap-sec .detail-sec .image-box {
        max-width: 320px;
    }
    .recap-sec .test-list li .list-box .name .image-box {
        width: 30px;
        height: 30px;
    }
    .recap-sec .test-list li .list-box .name {
        gap: 10px;
    }
    .recap-sec .test-list li .list-box .name h3 {
        font-size: 18px;
    }
    .recap-sec .test-list li .list-box i {
        font-size: 24px;
    }
    .recap-sec .test-list li .list-box p {
        font-size: 18px;
    }
    .recap-sec .info-box {
        font-size: 18px;
    }
    .intro-topic .details-left .image-box {
        max-width: 300px;
    }
    .intro-topic .details-left .video-box {
        height: 350px;
    }
    .intro-topic .details-right .image-box {
        max-width: 400px;
    }
    .lesson-done .details-right .image-box {
        max-width: 350px;
    }
    .lesson-done .details-left p {
        font-size: 20px;
    }
    .lesson-done .sec-heading {
        margin-bottom: 30px;
        font-size: 32px;
    }
    .text-answer-box .form-floating .form-control {
        max-height: 80px;
    }
    .question-slider {
        min-height: 51vh;
    }
    .text-answer-box .form-floating .form-control {
        font-size: 18px;
    }

    /* road-map design start  */
    .road-map-box .box {
        height: auto;
        margin-bottom: 25px !important;
    }
    .traning-video-road-map {
        margin-top: 50px;
    }

    /* road map css end */

    .test-lang-sec .slider-box .top-line-box {
        grid-template-columns: 25% 50% 25%;
    }
    .parent-main-box .school-analy-sec .chart-image .canvasjs-chart-container,
    .canvasjs-chart-tooltip {
        position: unset;
        width: 400px !important;
        height: 300px !important;
    }

    .parent-main-box .school-analy-sec .chart-image .canvasjs-chart-container .canvasjs-chart-canvas {
        width: 400px !important;
        height: 300px !important;
    }
}

@media only screen and (max-width: 1199px) {
    .test-lang-sec .slider-box .top-line-box h1 {
        font-size: 18px;
    }
    /* .test-lang-sec .count-slider{
        max-width: 100%;
    } */
    .test-lang-sec .count-slider {
        max-width: 350px;
    }
    .test-lang-sec .slider-box .top-line-box {
        grid-template-columns: 20% 60% 20%;
    }
    .parent-main-box .tab-content .activity-box table tr td,
    .parent-main-box .tab-content .activity-box table tr th {
        min-width: 90px !important;
        max-width: unset !important;
    }
    .question-slider .intro-box h3,
    .question-slider .question-box h3 {
        font-size: 18px;
    }
    .question-slider .intro-box ul li,
    .question-slider .question-box p {
        font-size: 16px;
    }
    .region-page,
    .subdvision-page,
    .result-sec,
    .knowledge-page,
    .knowledge-page,
    .test-lang-sec,
    .parent-details-page {
        height: 100%;
    }
    .home-banner {
        padding: 60px;
    }
    .apr-section .inner-box {
        gap: 35px;
    }
    .apr-section .inner-box svg {
        width: 35px;
        height: 35px;
    }
    .historic-sec .inner-box .image-box {
        max-width: 375px;
    }
    footer .top-box .social-box p {
        font-size: 15px;
    }
    .footer-link a {
        font-size: 14px;
    }
    .descri {
        font-size: 16px;
    }
    .self-asset-sec {
        gap: 70px;
    }
    .self-asset-sec .details-right .image-box,
    .self-asset-sec .details-right {
        max-width: 325px;
    }
    .self-asset-sec .grade-select-box .form-check .form-check-input,
    .self-asset-sec .grade-select-box .form-check {
        width: 150px;
    }
    .self-asset-sec .opt-select-box {
        gap: 30px;
    }
    .self-asset-sec .opt-select-box .form-check {
        width: 160px;
    }
    .self-asset-sec .opt-select-box .form-check .form-check-label {
        font-size: 14px;
    }
    .self-asset-sec .opt-select-box .form-check .form-check-label .image-box {
        width: 50px;
        height: 50px;
    }
    .self-asset-sec .email-box,
    .self-asset-sec .details-left .btn,
    .self-asset-sec .accept-req {
        width: 340px;
    }
    .result-sec-text p {
        font-size: 20px;
    }
    .result-sec-text .action-btn .btn {
        font-size: 14px;
    }
    .map-box .card {
        width: 315px;
        padding: 20px;
    }
    .parent-details-page .p-student-details .right-side .user-activity-box {
        gap: 30px;
        grid-template-columns: auto;
    }
    .parent-details-page .right-side {
        grid-template-columns: 100%;
    }
    .parent-details-page .right-side .in-right {
        display: flex;
        flex-wrap: wrap;
        gap: 0 20px;
        justify-content: space-between;
    }
    .parent-details-page .right-side .in-right h2 {
        width: 100%;
    }
    .parent-details-page .right-side .in-right .card-box {
        width: 48%;
    }
    .parent-details-page .p-student-details .right-side .user-activity-box .right {
        display: grid;
        grid-template-columns: 48% 48%;
        gap: 30px;
    }
    .parent-details-page .p-student-details .right-side .user-activity-box .right {
        margin-top: 20px;
    }
    .parent-details-page .p-student-details .right-side .user-activity-box {
        grid-template-columns: 100%;
        gap: 0;
    }
    .parent-details-page .p-student-details .right-side .user-activity-box .table-box {
        margin-bottom: 20px;
    }
    .chart-image-small {
        height: 165px;
    }
    .progress-ladder-sec .tab-content .tab-in-box {
        width: 100%;
    }
    .parent-main-box .school-analy-sec .chart-image .canvasjs-chart-container,
    .canvasjs-chart-tooltip {
        position: unset;
        width: 350px !important;
        height: 300px !important;
    }

    .parent-main-box .school-analy-sec .chart-image .canvasjs-chart-container .canvasjs-chart-canvas {
        width: 350px !important;
        height: 300px !important;
    }
}

@media only screen and (max-width: 1090px) {
    .parent-main-box .school-analy-sec .chart-image .canvasjs-chart-container,
    .canvasjs-chart-tooltip {
        width: 300px !important;
        height: 220px !important;
    }

    .parent-main-box .school-analy-sec .chart-image .canvasjs-chart-container .canvasjs-chart-canvas {
        width: 300px !important;
        height: 220px !important;
    }
}

@media only screen and (max-width: 991px) {
    .question-slider .test li{
        width: 45%;
      }
    .question-area .image-box-q {
        height: 200px;
        margin: 15px 0;
    }
    .map-box .card::after {
        display: none;
    }
    .map-box .card {
        position: fixed !important;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
        z-index: 2;
    }
    .region-card-bg::after {
        content: "";
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background-color: #00000078;
        transform: translate(0, 0);
        z-index: 1;
    }
    .question-slider {
        min-height: unset;
    }
    .drag-add-field .drag-drop-box .drag-box .select-drop span,
    .drag-drop-order .drag-drop-box .drag-box .select-drop span {
        font-size: 16px;
    }
    .question-slider .answer-box {
        gap: 30px;
    }
    .radio-center .radio-box {
        width: 80%;
    }
    .pink-label,
    .green-label {
        font-size: 14px;
        padding: 15px 30px;
        min-width: 250px;
    }
    .multi-maths .multi-box {
        gap: 30px 0;
    }
    .student-main .inner-box .details-right .right-box {
        display: grid;
        grid-template-columns: 60% 37%;
        gap: 20px;
    }
    .student-main .select-btn {
        padding: 10px 20px;
        font-size: 14px;
    }
    .student-main .progress-sec .box .CircularProgressbar {
        width: 140px;
        height: 140px;
    }
    .student-main .inner-box .details-left {
        padding-right: 0;
        border: none;
        grid-row: 2;
    }
    .student-main .inner-box {
        grid-template-columns: 100%;
        gap: 50px;
    }
    .region-select {
        min-width: 200px !important;
    }
    .historic-sec .action-btn .btn {
        max-width: 180px;
    }
    .prob-succes-sec .in-sec {
        flex-direction: column;
        align-items: center;
        gap: 40px;
    }
    .prob-succes-sec .image-box img {
        margin: 0;
    }
    .self-asset-sec .details-left {
        width: 100%;
    }
    .historic-sec .action-btn .btn {
        padding: 10px;
    }
    .story-padding {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .apr-padding {
        padding-bottom: 30px;
        padding-top: 30px;
    }
    header {
        padding: 15px 30px;
    }
    header ul {
        gap: 10px;
    }
    header .image-box {
        width: 120px;
    }
    .home-banner {
        padding: 40px 30px;
        min-height: 100%;
        gap: 0 30px;
    }
    .login-canvas {
        width: 350px;
    }
    .login-canvas .offcanvas-header {
        padding: 25px;
    }
    .login-canvas .offcanvas-body {
        padding: 0 25px 20px;
    }
    .canvas-title {
        font-size: 16px;
    }
    .login-canvas .form-group input {
        height: 45px;
    }
    .btn {
        padding: 10px 25px;
    }
    .login-canvas .btn {
        font-size: 14px;
    }
    .login-canvas .signup-with li {
        width: 40px;
        height: 40px;
    }
    .login-canvas .signup-with {
        gap: 20px;
    }
    .login-canvas .form-group label {
        font-size: 14px;
    }
    .login-canvas .form-group {
        margin-bottom: 15px;
    }
    .login-canvas .form-group input {
        font-size: 12px;
    }
    .home-banner .image-box {
        height: 200px;
    }
    .home-banner h1 {
        font-size: 20px;
        margin-bottom: 30px;
    }
    .home-banner p {
        font-size: 12px;
        line-height: 26px;
    }
    header .menu svg {
        width: 15px;
        height: 15px;
    }
    .plr-50 {
        padding-left: 30px;
        padding-right: 30px;
    }
    .pb-50 {
        padding-bottom: 30px;
    }
    .pt-50 {
        padding-top: 30px;
    }
    .historic-sec h2,
    .sec-heading {
        font-size: 24px;
    }
    .subdvision-page .sec-heading .form-select {
        font-size: 28px;
    }
    .btn {
        font-size: 14px;
    }
    .historic-sec .inner-box .image-box {
        max-width: 200px;
    }
    .historic-sec ul li svg {
        width: 20px;
        height: 20px;
    }
    .historic-sec ul li {
        font-size: 13px;
    }
    footer {
        padding: 20px 30px;
    }
    .region-num-sec .inner-box .box h2 {
        font-size: 16px;
    }
    .region-num-sec .inner-box .box ul li {
        gap: 15px;
    }
    .region-num-sec .inner-box .box ul li span {
        font-size: 24px;
    }
    .region-num-sec .inner-box .box ul li {
        font-size: 16px;
    }
    .progress-sec .box p {
        font-size: 14px;
    }
    .progress-sec {
        gap: 40px;
    }
    .self-asset-sec {
        flex-direction: column;
    }
    .self-asset-sec .details-right {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 100%;
    }
    .self-asset-sec .details-right .btn {
        margin-top: 30px;
        width: 100%;
        max-width: 340px;
    }
    .self-asset-sec .details-right .image-box {
        margin-top: 0;
    }
    .test-lang-sec .slider-box .time {
        position: relative;
        text-align: center;
        justify-content: end;
        width: 100%;
    }
    .parent-details-page .parent-main-box {
        flex-direction: column;
    }
    .parent-details-page .parent-main-box .left-side {
        width: 100%;
        max-width: unset;
        border-bottom: 1px solid #5182b2;
        border-right: none;
        margin: 0;
        padding: 0;
        padding-bottom: 30px;
    }
    .parent-details-page .parent-main-box .left-side .inner-box {
        display: grid;
        grid-template-columns: auto auto;
        gap: 50px;
    }
    .parent-details-page .left-side .inner-box .box {
        margin: 0;
    }
    .parent-details-page .sec-heading {
        text-align: center;
    }
    .parent-details-page .p-student-details .right-side,
    .parent-details-page .right-side {
        margin-top: 30px;
    }
    .parent-details-page .right-side .in-left .title-top {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0;
        margin-bottom: 20px;
    }
    .parent-details-page .right-side table tr th,
    .parent-details-page .right-side table tr td {
        font-size: 14px;
    }
    .parent-details-page .right-side .in-right .card-box ul li {
        font-size: 14px;
    }
    .setting-page {
        grid-template-columns: 100%;
    }
    .setting-page .s-service-box {
        padding: 0;
        padding-bottom: 30px;
        margin: 0;
        margin-bottom: 30px;
        border-right: none;
        border-bottom: 1px solid #9b4692;
    }
    .setting-page .s-shopping-cart-box .text-image {
        max-width: 575px;
        margin: auto;
    }
    .setting-page .s-shopping-cart-box {
        display: flex;
        flex-direction: column;
    }
    .setting-page .s-shopping-cart-box .sec-heading {
        order: 1;
    }
    .setting-page .s-shopping-cart-box .cart-box {
        order: 2;
    }
    .setting-page .s-shopping-cart-box .buy-box {
        order: 3;
        margin: 0;
    }
    .parent-main-box .nav-tabs .nav-item .nav-link {
        font-size: 16px;
    }
    .education-map-box .in-sec .bar-image {
        max-width: 500px;
        width: auto;
    }
    .education-map-box .in-sec .bar-image img {
        width: 100%;
    }
    .education-map-box .chart-image-small {
        height: 190px;
        gap: 10px;
    }
    .progress-sec .box .CircularProgressbar {
        width: 80px;
        height: 80px;
    }
    .forcetest-inst .instruction-box .box {
        padding: 30px 20px;
    }
    .forcetest-inst .instruction-box .box::after {
        width: 30px;
        height: 30px;
        left: -16px;
    }
    .forcetest-inst .instruction-box .box p {
        font-size: 16px;
    }
    .self-asset-sec .opt-select-box {
        margin-top: 40px;
    }
    .forcetest-inst .self-asset-sec {
        gap: 30px;
    }
    .forcetest-inst .self-asset-sec .skip {
        align-self: center !important;
    }
    .forcetest-inst .self-asset-sec .opt-select-box {
        justify-content: center;
    }
    .progress-ladder-sec .self-asset-sec {
        flex-direction: row;
    }
    .progress-ladder-sec .self-asset-sec .details-left {
        width: 65%;
    }
    .progress-ladder-sec .self-asset-sec .details-right {
        width: 25%;
    }
    .progress-ladder-sec .self-asset-sec .details-right .image-box {
        margin: 0 auto;
    }
    .recap-sec .inner-box {
        gap: 50px;
    }
    .recap-sec .detail-sec .image-box {
        margin: auto;
    }
    .recap-sec .test-list {
        width: 80%;
        margin: auto;
    }
    .recap-sec .info-box {
        width: 70%;
        margin: 30px auto 0;
    }
    .intro-topic .details-left p {
        font-size: 18px;
    }
    .intro-topic .details-left .video-box {
        height: 250px;
    }
    .intro-topic .details-left .image-box {
        max-width: 250px;
    }
    .intro-topic .details-right .image-box {
        max-width: 250px;
    }
    .lesson-done .details-right .image-box {
        max-width: 350px;
    }
    .payment-detail-modal .modal-dialog {
        max-width: 600px;
        padding: 0 20px;
    }
    .footer-link {
        gap: 15px;
    }
}

@media only screen and (max-width: 767px) {
    .education-map-box .d-grid{
        grid-template-columns: 100%;
        gap: 20px;
    }
    .education-map-box .d-grid ul{
        padding-left: 25px;
    }
    .test-lang-sec .slider-box .time {
        justify-content: center;
    }
    .test-lang-sec .top-line-box .right-side {
        align-items: center;
    }
    .test-lang-sec .slider-box .top-line-box {
        grid-template-columns: 100%;
        text-align: center;
        gap: 10px;
    }
    .multi-maths .multi-box .flex-title {
        justify-content: space-between;
    }
    .radio-center .radio-box {
        width: 100%;
    }
    .region-select {
        min-width: 200px;
    }
    .region-select span {
        font-size: 14px;
    }
    .region-select select {
        font-size: 14px;
    }
    .chart-image-small {
        height: 140px;
    }
    header {
        padding: 15px 20px;
    }
    header ul li {
        font-size: 12px;
    }
    header ul li span {
        margin-right: 5px;
    }
    .lang-select .form-select {
        font-size: 12px;
        background-position: right;
        padding-right: 20px;
    }
    header .image-box {
        width: 100px;
    }
    .home-banner {
        gap: 0 30px;
    }
    .our-story {
        gap: 0 30px;
    }
    .our-story .image-box {
        width: 130px;
    }
    .title {
        font-size: 16px;
    }
    .apr-section .inner-box {
        grid-template-columns: auto auto;
    }
    .apr-section .inner-box .box .image-box {
        width: 35px;
        height: 40px;
    }
    .historic-sec .inner-box .image-box {
        max-width: 170px;
    }
    .historic-sec ul {
        gap: 15px;
    }
    footer .top-box .social-box .social-link {
        gap: 25px;
    }
    .footer-link {
        gap: 15px;
    }
    .btn {
        padding: 10px 15px;
    }
    .historic-sec .action-btn {
        gap: 10px;
    }
    footer .top-box .social-box p {
        display: none;
    }
    footer .bottom-box {
        flex-direction: column;
        align-items: center;
    }
    footer .top-box {
        align-items: center;
    }
    .region-num-sec .inner-box {
        grid-template-columns: auto;
        justify-content: center;
    }
    .progress-sec {
        grid-template-columns: auto;
        justify-content: center;
    }
    .progress-sec .box {
        flex-direction: row;
    }
    .progress-sec .box p {
        text-align: start;
        max-width: 250px;
    }
    .progress-sec .box .CircularProgressbar {
        flex-shrink: 0;
    }
    .progress-sec {
        gap: 25px;
    }
    .progress-sec .box {
        gap: 30px;
    }
    .map-box .card ul {
        align-items: start;
        gap: 10px !important;
    }
    .question-slider .intro-box h3,
    .question-slider .question-box h3 {
        font-size: 16px;
    }
    .question-slider .intro-box ul li,
    .question-slider .question-box p {
        font-size: 14px;
    }
    .parent-main-box .tab-content .small-title {
        font-size: 18px;
    }
    .parent-main-box .summary-box .owl-image {
        width: 120px;
    }
    .stn-summary .owl-image {
        gap: 15px;
    }
    .stn-summary .owl-image img {
        width: 95px;
    }
    .parent-main-box .tab-content .small-topbar-area button {
        font-size: 12px;
    }
    .education-map-box .in-sec {
        flex-direction: column;
        gap: 20px;
    }
    .parent-main-box .nav-tabs {
        justify-content: flex-start;
    }
    .parent-main-box .nav-tabs .nav-item .nav-link {
        padding: 5px 10px;
    }
    .historic-sec .action-btn .btn {
        font-size: 12px;
        max-width: fit-content;
    }
    .forcetest-inst .instruction-box .box {
        padding: 20px;
    }
    .forcetest-inst .instruction-box .box::after {
        width: 25px;
        height: 25px;
        left: -13px;
    }
    .forcetest-inst .instruction-box .box p {
        font-size: 14px;
    }
    .progress-ladder-sec .self-asset-sec {
        flex-direction: column;
    }
    .progress-ladder-sec .self-asset-sec .details-left {
        width: 100%;
    }
    .progress-ladder-sec .self-asset-sec .details-right {
        width: 100%;
    }
    .progress-ladder-sec .self-asset-sec .details-right {
        flex-direction: row;
    }
    .intro-topic .details-right .image-box {
        display: none;
    }
    .intro-topic .details-left .video-box {
        height: 100%;
    }
    .intro-topic .details-left .video-box video {
        width: 100%;
        height: 100%;
    }
    .intro-topic .details-left .image-box {
        max-width: 300px;
    }
    .lesson-done .details-right {
        display: flex;
        align-items: center;
    }
    .lesson-done .details-right p {
        line-height: 30px;
    }
    .lesson-done .details-right .image-box {
        width: 50%;
        flex-shrink: 0;
    }
    .parent-main-box .tab-content .activity-box.chart-main-flex {
        grid-template-columns: 100%;
        /* gap: 50px; */
    }
    .parent-main-box .tab-content .activity-box.chart-main-flex .inner-box {
        border: none;
        padding: 0;
    }
    .parent-main-box .tab-content .activity-box.chart-main-flex .inner-box:nth-child(1) {
        border: none;
        border-bottom: 1px solid #5182b24d;
        padding: 0;
        padding-bottom: 20px;
        margin-bottom: 20px;
    }
    .parent-main-box .school-analy-sec .chart-image .canvasjs-chart-container,
    .canvasjs-chart-tooltip {
        position: unset;
        width: 500px !important;
        height: 350px !important;
    }

    .parent-main-box .school-analy-sec .chart-image .canvasjs-chart-container .canvasjs-chart-canvas {
        width: 500px !important;
        height: 350px !important;
    }
    .student-main .inner-box .details-right .right-box{
        grid-template-columns: 55% 42%;
    }
}

@media only screen and (max-width: 575px) {
    .text-danger {
        font-size: 12px;
    }
    .after-login .tab-content ul li {
        border-bottom: 1px solid #5182b2;
    }
    .after-login .bottom-box {
        position: unset;
        width: 100%;
    }
    .question-area .image-box-q {
        height: 150px;
        margin: 10px 0;
    }
    .setting-page .s-service-box .boxes .box-top {
        font-size: 16px;
    }
    .setting-page .s-shopping-cart-box .buy-box {
        flex-direction: column;
    }
    .reset-password .details-left form {
        width: 100%;
        margin: auto;
    }
    .question-slider textarea {
        font-size: 14px;
    }
    .drag-drop-order .drag-drop-box {
        margin-top: 30px;
    }
    .radio-center .radio-box {
        margin: 30px 0 0;
    }
    .radio-center .radio-box .image-label img {
        height: 150px;
    }
    .question-slider .answer-box .btn {
        padding: 10px;
        max-width: 250px;
    }
    .pink-label,
    .green-label {
        font-size: 12px;
        padding: 10px;
        max-width: 250px;
        width: 100%;
    }
    .question-slider .radio-box label {
        font-size: 16px;
    }
    .font-24-semibold {
        font-size: 16px;
    }
    .student-main .progress-sec .box .CircularProgressbar {
        width: 100px;
        height: 100px;
    }
    .student-main .action-btn .btn {
        margin: 0;
        padding: 10px;
    }
    .student-main .action-btn {
        display: grid;
        grid-template-columns: 45% 45%;
        gap: 20px;
        justify-content: center;
        margin-top: 30px;
    }
    .student-main .select-btn {
        margin: 0;
        font-size: 12px;
        padding: 10px;
    }
    .student-main .inner-box .details-right .right-box {
        display: grid;
        grid-template-columns: 100%;
        gap: 0px;
    }
    .chart-image-small {
        height: 220px;
    }
    .prob-succes-sec .in-sec .owl-image {
        width: 140px;
    }
    .parent-details-page .right-side .in-left .title-top h3 {
        font-size: 14px;
    }
    .test-lang-sec .swiper-button-prev:after,
    .test-lang-sec .swiper-button-next.swiper-button-next:after {
        font-size: 24px;
    }
    .self-asset-sec .grade-select-box .form-check,
    .self-asset-sec .grade-select-box .form-check .form-check-input {
        height: 50px;
    }
    .home-banner {
        min-height: calc(100vh - 65.86px);
    }
    .lang-select .form-select {
        padding-left: 0;
        background-position: right;
        padding-right: 15px;
        background-size: 9px;
    }
    .lang-select .image-container {
        display: none;
    }
    header {
        padding: 15px 20px;
        /* flex-direction: column; */
    }
    header ul li {
        font-size: 12px;
    }
    header .image-box {
        width: 80px;
    }
    .plr-50 {
        padding-left: 20px;
        padding-right: 20px;
    }
    .home-banner {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 20px;
        justify-content: center;
    }
    .home-banner .image-box {
        max-width: 300px;
    }
    .home-banner h1 {
        font-size: 24px;
        margin-bottom: 15px;
        margin-top: 15px;
    }
    .home-banner p {
        margin-bottom: 20px;
    }
    .our-story {
        display: block;
    }
    .our-story .image-box {
        width: 100px;
        height: auto;
        margin-bottom: 20px;
    }
    .descri {
        font-size: 14px;
    }
    .title {
        margin-bottom: 15px;
    }
    .apr-section .inner-box svg {
        width: 25px;
        height: 25px;
    }
    .apr-section .inner-box .box {
        gap: 10px;
    }
    .apr-section .inner-box .box .image-box {
        width: 35px;
        height: 40px;
    }
    .historic-sec h2,
    .sec-heading {
        font-size: 20px;
    }
    .subdvision-page .sec-heading .form-select {
        font-size: 20px;
    }
    .historic-sec .action-btn {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .historic-sec .inner-box {
        flex-direction: column;
        align-items: center;
    }
    .historic-sec .inner-box .image-box {
        max-width: 150px;
    }
    .btn {
        padding: 10px;
        font-size: 12px;
    }
    .apr-section .inner-box {
        gap: 24px;
    }
    .apr-section .title {
        margin-bottom: 15px;
    }
    footer {
        padding: 20px 12px;
    }
    footer .top-box .footer-logo {
        width: 110px;
        height: 45px;
    }
    .footer-link {
        flex-wrap: wrap;
        gap: 10px 15px;
        justify-content: center;
    }
    footer .top-box {
        flex-wrap: wrap;
    }
    .progress-sec .box .CircularProgressbar {
        width: 70px;
        height: 70px;
    }
    .self-asset-sec .email-box,
    .self-asset-sec .details-left .btn,
    .self-asset-sec .accept-req {
        max-width: 340px;
        width: 100%;
    }
    .self-asset-sec .grade-select-box {
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
    }
    .self-asset-sec .opt-select-box {
        justify-content: center;
        flex-wrap: wrap;
        gap: 15px;
    }
    .self-asset-sec .caption {
        font-size: 16px;
    }
    .result-sec-text p {
        font-size: 16px;
    }
    .question-slider .answer-box {
        flex-direction: column;
        gap: 25px;
    }
    .test-lang-sec .mySwiper-user .swiper-slide .count-number {
        width: 40px;
        height: 40px;
    }
    .parent-details-page .p-student-details .right-side .user-activity-box .right {
        grid-template-columns: 100%;
        gap: 0;
    }
    .parent-details-page .parent-main-box .left-side .inner-box {
        grid-template-columns: 100%;
        gap: 20px;
    }
    .parent-details-page .right-side table tr th:last-child,
    .parent-details-page .right-side table tr td:last-child {
        min-width: 150px !important;
        max-width: 150px !important;
    }
    .parent-details-page .right-side table tr td:first-child,
    .parent-details-page .right-side table tr th:first-child,
    .parent-details-page .right-side table tr td:first-child,
    .parent-details-page .p-student-details .right-side table tr th,
    .parent-details-page .p-student-details .right-side table tr td {
        min-width: 100px !important;
        max-width: 10px !important;
    }

    .parent-details-page .right-side .in-right .card-box {
        width: 100%;
        margin-bottom: 30px;
    }
    .parent-details-page .right-side .in-right h2 {
        margin-bottom: 20px;
    }
    .choose-school-canvas .select-box label {
        font-size: 14px;
    }
    .choose-school-canvas .form-select {
        font-size: 14px;
    }
    .login-canvas .btn {
        font-size: 12px;
    }
    .setting-page .s-service-box .boxes .topbar {
        flex-wrap: wrap;
        margin-bottom: 10px;
    }
    .setting-page .s-service-box .boxes .topbar p:nth-child(1) {
        font-size: 14px;
        width: 50%;
    }
    .setting-page .s-shopping-cart-box .cart-box .box .product-title h3 {
        font-size: 14px;
    }
    .setting-page .s-shopping-cart-box .text-image p {
        line-height: 22px;
    }
    .setting-page .s-service-box .table-box table {
        margin: 0;
    }
    .setting-page .s-service-box .boxes {
        padding-bottom: 20px;
        margin-bottom: 20px;
    }
    .setting-page .s-service-box .boxes .topbar p:nth-child(2) {
        font-size: 14px;
        width: 40%;
    }
    .setting-page .s-service-box .boxes .topbar .plus-box span {
        font-size: 12px;
    }
    .setting-page .s-service-box .boxes .topbar .plus-box svg {
        width: 20px;
        height: 20px;
    }
    .setting-page .s-service-box .boxes .topbar .plus-box {
        gap: 5px;
    }
    .setting-page .s-service-box .table-box table tr td .dots {
        display: block;
    }
    .setting-page .s-service-box .table-box table tr td span {
        display: none;
    }
    .parent-main-box .tab-content .activity-box {
        grid-template-columns: 100%;
    }
    .parent-main-box .tab-content .activity-box .inner-box:nth-child(1) {
        border: none;
        border-bottom: 1px solid #5182b24d;
        padding: 0;
        padding-bottom: 20px;
        margin-bottom: 20px;
    }
    .parent-main-box .tab-content .activity-box .inner-box:nth-child(2) {
        padding: 0;
    }
    .forcetest-inst .instruction-box {
        grid-template-columns: 100%;
        gap: 50px;
    }
    .forcetest-inst .instruction-box .image-box {
        grid-row: 2;
        max-width: 240px;
    }
    .forcetest-inst .instruction-box .box {
        border-radius: 20px !important;
        padding: 25px 15px;
    }
    .forcetest-inst .instruction-box .box::after {
        left: 50%;
        width: 35px;
        height: 25px;
    }
    .forcetest-inst .instruction-box .box:nth-child(2)::after {
        content: "";
        bottom: -13px;
        transform: skew(0deg, 35deg);
        border-top: none;
        border-right: 1px solid;
        border-left: none;
    }
    .forcetest-inst .instruction-box .box:nth-child(3)::after {
        content: "";
        top: -13px;
        transform: skew(0deg, 325deg);
        border-right: 1px solid;
        border-bottom: none;
        border-left: 0;
    }
    .forcetest-inst .self-asset-sec .skip {
        font-size: 14px;
    }
    .progress-ladder-sec .nav-tabs {
        gap: 10px;
    }
    .progress-ladder-sec .tab-content .tab-in-box {
        margin-bottom: 20px;
    }
    .progress-ladder-sec .tab-content .tab-in-box h3 {
        font-size: 16px;
    }
    .progress-ladder-sec .nav-tabs .nav-item .nav-link {
        font-size: 16px;
    }
    .progress-ladder-sec .tab-content .tab-in-box ul li p {
        font-size: 14px;
    }
    .progress-ladder-sec .self-asset-sec {
        gap: 30px;
    }
    .recap-sec .test-list li .list-box .name h3 {
        font-size: 14px;
    }
    .recap-sec .test-list li .list-box p {
        font-size: 14px;
    }
    .intro-topic .details-left p {
        font-size: 16px;
    }
    .lesson-done .sec-heading {
        font-size: 26px;
    }
    .lesson-done .details-left p {
        font-size: 18px;
        margin-bottom: 20px;
    }
    .radio-center .radio-box .form-check {
        width: 100%;
    }
    .question-slider .intro-box ul {
        padding: 0;
    }

    /* road-map css start */
    .up-heading-status h2 {
        font-size: 18px;
    }
    .road-map-box h2 {
        font-size: 14px;
    }
    .road-map-box .icon-box svg {
        width: 22px;
        height: 22px;
    }
    .road-map-box .box:nth-child(8n + 2) {
        margin-left: 15%;
    }
    .road-map-box .box:nth-child(8n + 3) {
        margin-left: 5%;
    }
    .road-map-box .box:nth-child(8n + 4) {
        margin-left: 10%;
    }
    .road-map-box .box:nth-child(8n + 6) {
        margin-right: 5%;
    }
    .road-map-box .box:nth-child(8n + 7) {
        margin-right: 0%;
    }
    .road-map-box .box:nth-child(8n + 8) {
        margin-right: 15%;
    }
    .road-map-box .bonus-task svg {
        width: 120px;
    }
    /* road-map css end */

    .payment-detail-modal .modal-dialog {
        max-width: auto;
        padding: 0;
    }
    .payment-detail-modal .details-box .box {
        flex-direction: column;
    }
    .payment-detail-modal .details-box .box .left {
        width: 100%;
    }

    .parent-main-box .school-analy-sec .chart-image .canvasjs-chart-container,
    .canvasjs-chart-tooltip {
        width: 400px !important;
        height: 350px !important;
    }

    .parent-main-box .school-analy-sec .chart-image .canvasjs-chart-container .canvasjs-chart-canvas {
        width: 400px !important;
        height: 350px !important;
    }
    .breadcumb {
        gap: 7px;
    }
    .breadcumb li {
        font-size: 14px;
    }
}

@media only screen and (max-width: 480px) {
    header {
        padding: 15px 12px;
    }
    header ul li span {
        margin-right: 0;
    }
    .map-box .card .btn {
        padding: 5px;
    }
    .map-box .card {
        width: 295px;
        padding: 15px;
    }
    .pink-label,
    .green-label {
        max-width: 100%;
    }
    .question-slider .answer-box .btn {
        max-width: 100%;
    }
    .question-slider .radio-box label {
        font-size: 14px;
    }
    .font-24-semibold {
        font-size: 14px;
    }
    .radio-center .radio-box .image-label img {
        height: 115px;
    }
    header ul .lang-select span {
        display: none;
    }
    header ul .menu {
        padding: 8px;
    }
    header ul .menu span {
        display: none;
    }
    .chart-image-small {
        height: 170px;
    }
    .subdvision-page .sec-heading {
        flex-direction: column;
    }
    .home-banner .image-box {
        max-width: 215px;
    }
    .home-banner h1 {
        font-size: 20px;
    }
    .home-banner p {
        line-height: 20px;
    }
    .apr-section .inner-box {
        grid-template-columns: auto;
    }
    footer .top-box .social-box .social-link {
        gap: 15px;
    }
    .parent-details-page .right-side table tr th,
    .parent-details-page .right-side table tr td {
        font-size: 13px;
        padding: 5px !important;
    }
    .recap-sec .test-list {
        width: 100%;
    }
    .recap-sec .test-list li .list-box .name .image-box {
        width: 25px;
        height: 25px;
    }
    .recap-sec .info-box {
        width: 85%;
        font-size: 16px;
        padding: 10px;
    }
    .setting-page .s-service-box .boxes.custom-box .topbar p {
        width: 100%;
    }
    .student-main .action-btn{
        grid-template-columns: unset;
    }
}

@media only screen and (max-width: 420px) {
    .question-slider .test li{
        width: 100%;
    }
    .p-toast {
        width: auto;
        max-width: 22rem;
        margin-left: 15px;
    }
    .p-toast .p-toast-message .p-toast-message-content svg {
        width: 22px;
        height: 22px;
    }
    .p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
        font-size: 14px;
    }
    .login-canvas .form-check label {
        font-size: 14px;
    }
    .parent-main-box .school-analy-sec .chart-image .canvasjs-chart-container,
    .canvasjs-chart-tooltip {
        width: 350px !important;
        height: 300px !important;
    }

    .parent-main-box .school-analy-sec .chart-image .canvasjs-chart-container .canvasjs-chart-canvas {
        width: 350px !important;
        height: 300px !important;
    }
}

@media only screen and (max-width: 380px) {
    .parent-main-box .school-analy-sec .chart-image .canvasjs-chart-container,
    .canvasjs-chart-tooltip {
        width: 300px !important;
        height: 270px !important;
    }

    .parent-main-box .school-analy-sec .chart-image .canvasjs-chart-container .canvasjs-chart-canvas {
        width: 300px !important;
        height: 270px !important;
    }
}

@media only screen and (max-width: 360px) {
    .education-map-box .chart-image-small {
        height: 150px;
    }
    .chart-image-small {
        height: 140px;
    }
    header {
        padding: 15px;
    }
    footer {
        padding: 20px 15px;
    }
    header .image-box {
        width: 75px;
    }
    header li {
        text-align: center;
    }
    .plr-50 {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media only screen and (max-width: 320px) {
    .map-box .card {
        width: 260px;
    }
    .parent-main-box .school-analy-sec .chart-image .canvasjs-chart-container,
    .canvasjs-chart-tooltip {
        width: 250px !important;
        height: 220px !important;
    }

    .parent-main-box .school-analy-sec .chart-image .canvasjs-chart-container .canvasjs-chart-canvas {
        width: 250px !important;
        height: 220px !important;
    }
}
